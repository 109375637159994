import React, { useState } from "react";
import { Avatar, Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { ControlsChevronDown } from "@heathmont/moon-icons-tw";

interface Employee {
  img: string;
  name: string;
  designation: string;
}

interface DropdownUserProps {
  data: Employee[];
}

const DropDownUser: React.FC<DropdownUserProps> = ({ data }) => {
  const [selectedItem, setSelectedItem] = useState<Employee | null>(null);

  return (
    <Dropdown
      value={selectedItem}
      onChange={(item) => setSelectedItem(item as Employee)}
    >
      <Dropdown.Trigger>
        <button className="w-full border-beerus border px-3 py-2 rounded-lg font-normal text-moon-14 justify-between inline-flex items-center bg-white">
          {selectedItem ? (
            <div className="text-gray-900 text-moon-14 group flex w-full items-center gap-[15px] rounded-md px-2 py-2 focus:bg-goku focus:text-hit hover:bg-goku hover:text-hit">
              <span className="inline-flex rounded-full shadow-md border-[2px] border-whis">
                <Avatar
                  size="md"
                  className={`rounded-full overflow-hidden`}
                  imageUrl={selectedItem.img}
                />
              </span>
              <div className="flex-1 flex flex-col items-start">
                <h4 className="text-moon-14 text-blackcst">
                  {selectedItem.name}
                </h4>
                <span className="text-gray-600 text-moon-12">
                  {selectedItem.designation}
                </span>
              </div>
            </div>
          ) : (
            <>
              Select Employee
              <i>
                <ControlsChevronDown className="text-moon-18" />
              </i>
            </>
          )}
        </button>
      </Dropdown.Trigger>
      <Dropdown.Options className="max-h-[200px]">
        {data.map((item, index) => (
          <Dropdown.Option value={item} key={index}>
            {({ selected, active }) => (
              <MenuItem
                isActive={active}
                isSelected={selected}
                onClick={() => setSelectedItem(item)}
              >
                <div
                  className={`${
                    selected || active
                      ? "bg-goku text-cyan-500"
                      : "text-gray-900"
                  } text-moon-14 group flex w-full items-center gap-[15px] rounded-md px-2 py-2 focus:bg-goku focus:text-hit hover:bg-goku hover:text-hit`}
                >
                  <span className="inline-flex rounded-full shadow-md border-[2px] border-whis">
                    <Avatar
                      size="md"
                      className={`rounded-full overflow-hidden`}
                      imageUrl={item.img}
                    />
                  </span>
                  <div className="flex-1 flex flex-col items-start">
                    <h4 className="text-moon-14 text-blackcst"> {item.name}</h4>
                    <span className="text-gray-600 text-moon-12">
                      {" "}
                      {item.designation}
                    </span>
                  </div>
                </div>
              </MenuItem>
            )}
          </Dropdown.Option>
        ))}
      </Dropdown.Options>
    </Dropdown>
  );
};

export default DropDownUser;
