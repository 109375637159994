import { ApiErrorResponse, create } from "apisauce";
import { apiBaseUrl } from "../constants/environmentConstants/environmentConstants";
import { logout, refreshToken } from "../services/auth.service";
import spinnerSvc from "../utils/spinner-service";
import { removeUser } from "../features/Auth Slice/authSlice";
import { store } from "../store/store";

let isRefreshing = false;
let waitingQueue: any[] = [];

if (!apiBaseUrl) {
  throw new Error("No Api apiBaseUrl");
}

export const api = create({
  baseURL: process.env.REACT_APP_API_URL_STAGING,
  headers: { Accept: "application/json" },
});

const processQueue = (error: ApiErrorResponse<any> | null, token = null) => {
  waitingQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  isRefreshing = false;
  waitingQueue = [];
};

api.axiosInstance.interceptors.request.use(
  async (config: any) => {
    const accessToken = sessionStorage.getItem("acToken") || "";
    if (accessToken !== "") {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (
      config?.url !== "/employee/getNotifications" &&
      config?.url !== "/employee/getUnreadNotifications"
    )
      spinnerSvc.start();
    return config;
  },

  (err) => console.error(err),
);

api.axiosInstance.interceptors.response.use(
  (response: any) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          waitingQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return api.axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      const accessToken = await refreshToken();
      if (accessToken) {
        api.axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
        processQueue(null, accessToken);
        return api.axiosInstance(originalRequest);
      } else {
        spinnerSvc.stop();
        logout();
        // store.dispatch(removeUser());
        return;
      }
    }
    console.error(err.response.data);

    switch (err.response?.status) {
      case 400:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 401:
        console.error(err);
        logout();
        isRefreshing = false;
        spinnerSvc.stop();

        return err;

      case 404:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 500:
        console.error(err);

        spinnerSvc.stop();

        return err;

      default:
        console.error(err);

        spinnerSvc.stop();

        return err;
    }
  },
);

// import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";

// import { apiBaseUrl } from "../constants/environmentConstants";

// import { refreshToken } from "../services/auth.service";

// import { logout } from "../services/auth.service";

// import spinnerSvc from "./spinner-service";

// if (!apiBaseUrl) {

//   throw new Error("No Api apiBaseUrl");

// }

// axios.defaults.headers.post["Content-Type"] = "application/json";

// const createApi = (path: string) => {

//   const api = axios.create({

//     baseURL: `${apiBaseUrl}${path}`,

//     timeout: 10000,

//     headers: {

//       "Content-Type": "application/json",

//     },

//   });

//   api.interceptors.request.use(

//     async (config: InternalAxiosRequestConfig) => {

//       const accessToken = sessionStorage.getItem("atToken") || "";

//       if (accessToken !== "") {

//         config.headers["Authorization"] = `Bearer ${accessToken}`;

//       }

//       spinnerSvc.start();

//       return config;

//     },

//     (error: AxiosError) => Promise.reject(error),

//   );

//   api.interceptors.response.use(

//     (response: any) => {

//       spinnerSvc.stop();

//       return response;

//     },

//     async (err) => {

//       const originalRequest = err.config;

//       spinnerSvc.stop();

//       if (err.response?.status === 401 && !originalRequest._retry) {

//         try {

//           const data = await refreshToken();

//           if (data?.accessToken) {

//             api.defaults.headers.common[

//               "Authorization"

//             ] = `Bearer ${data?.accessToken}`;

//             originalRequest._retry = true;

//             return api(originalRequest);

//           } else {

//             throw new Error("Token refresh failed");

//           }

//         } catch (refreshError) {

//           spinnerSvc.stop();

//         }

//       }

//     },

//   );

//   return api;

// };

// export default createApi;

// // function logoutAndNavigateToHome() {

// //   logout();

// //   const navigate = useNavigate();

// //   navigate("/login");

// // }
