import React, { useEffect, useState } from "react";
import NurseScheduleHeader from "./widgets/schedule-header";
import MonthlyScheduler from "./widgets/monthly/MonthlyScheduler";
import NurseHeader from "../../../Components/layouts/header/nurse-header";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../store/store";
import {
  setDate,
  setSelectedCalendar,
} from "../../../features/Time Slice/timeSlice";
import moment from "moment";
import WeeklyCalendar from "../../../Components/Calendar/weekly";
import NurseWeeklyActivities from "../week/widgets/weekly-calendar/NurseWeeklyActivities";

const NurseScheduler = () => {
  const [dayNightFilter, setDayNightFilter] = useState<string>("All");
  // const month = [{ name: "November 2023" }, { name: "December 2023" }];
  const handleButtonClick = (button: string) => {
    setDayNightFilter(button);
  };

  // useEffect(() => {
  //   store.dispatch(setSelectedCalendar("month"));
  // }, []);

  const [month, setMonth] = useState({
    name: moment().format("MMMM"),
    monthName: moment().format("MMMM"),
    label: moment().format("M"),
  });

  const [year, setYear] = useState({
    name: moment().format("YYYY"),
    year: moment().format("YYYY"),
    label: "year",
  });
  const currentDate = moment();
  const currentWeek = Math.ceil(currentDate.date() / 7);

  const [week, setWeek] = useState({
    name: `Week ${currentWeek}`,
    weekLabel: "Week",
    label: currentWeek,
  });

  const selectedCalendar = useSelector(
    (state: RootState) => state.time.selectedCalendar
  );

  return (
    <>
      <div className="h-screen flex flex-col">
        <NurseHeader />
        <div className="flex-1 flex flex-col">
          <NurseScheduleHeader
            year={year}
            month={month}
            setYear={setYear}
            setMonth={setMonth}
            week={week}
            setWeek={setWeek}
          />
          {selectedCalendar === "month" ? (
            <MonthlyScheduler month={month} year={year} />
          ) : selectedCalendar === "week" ? (
            <div className="flex-1  flex flex-col">
              <NurseWeeklyActivities month={month} year={year} week={week} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default NurseScheduler;
