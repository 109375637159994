import { api } from "../utils/axios";

// // Get the current date
// const currentDate = new Date();
// // Get the year of the current date
// const currentYear = currentDate.getFullYear();
// // Get the month of the current date (0-indexed, January is 0)
// const currentMonth = currentDate.getMonth();
// // Get the current day of the month
// const currentDay = currentDate.getDate();
// // Calculate the number of days in the current month
// const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

// export const employeesMonthlySchedule = async (dayNight: string) => {
//   // Get the authentication token from local storage
//   const token = localStorage.getItem("authToken");
//   const apiUrl = process.env.REACT_APP_API_URL;
//   // Fetch the monthly employee data using axios
//   const response = await axios.get(
//     `${apiUrl}/nurse/getSpecificEmployeeDataForMonth/${
//       currentMonth + 1
//     }/${currentYear}?${dayNight}=true`,
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     },
//   );

//   // Check if the data was successfully fetched
//   if (response.data) {
//     // Extract the employee info from the fetched data
//     const employeeInfo = response.data.employeeInfo;

//     // Set the employee data
//     return employeeInfo;
//   }
// };

export const getSpecificEmployeeDataForMonth = async (
  month: number,
  year: number
) => {
  try {
    const { data, ok } = await api.get(
      `/nurse/getSpecificEmployeeDataForMonth/${month}/${year}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const getSpecificEmployeeDataForWeek = async (
  shift: string,
  week: number,
  month: number,
  year: number
) => {
  try {
    const { data, ok } = await api.get(
      `/nurse/getSpecificEmployeeDataWeek/${month}/${year}/${week}/${shift}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getDayInfo = async (date: number, month: number, year: number) => {
  try {
    const { data, ok } = await api.get(
      `/nurse/getDayInfoNurse/${date}/${month}/${year}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
