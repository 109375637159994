import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

export const currentDay: number = Number(moment().format("DD"));
export const currentMonth: string = moment().format("MMMM");
export const currentMonthIndex: number = moment().month() + 1; // Adding 1 to get the correct month index
export const currentYear: number = Number(moment().format("YYYY"));
export const currentWeek =
  moment().week() - moment(Date()).startOf("month").week() + 1;
export interface DateInfo {
  day: number;
  month: string;
  currentMonth: number;
  year: number;
  week: number;
}

interface Section {
  _id: string;
  sectionName: string;
}
interface TimeState {
  selectedShift: string;
  selectedCalendar: string;
  selectedDuration: string;
  section: Section;
  date: DateInfo;
}

const initialState: TimeState = {
  selectedShift: "All",
  selectedCalendar: "month",
  selectedDuration: "",
  section: {
    _id: "",
    sectionName: "",
  },
  date: {
    day: currentDay,
    month: currentMonth,
    currentMonth: currentMonthIndex,
    year: currentYear,
    week: currentWeek,
  },
};

const timeSlice = createSlice({
  name: "time",
  initialState,
  reducers: {
    setSelectedShift: (state, action: PayloadAction<string>) => {
      state.selectedShift = action.payload;
    },
    setSelectedCalendar: (state, action: PayloadAction<string>) => {
      state.selectedCalendar = action.payload;
    },
    setSelectedDuration: (state, action: PayloadAction<string>) => {
      state.selectedDuration = action.payload;
    },
    setDate: (state, action: PayloadAction<Partial<DateInfo>>) => {
      state.date = { ...state.date, ...action.payload };
    },
    setSelectedSection: (state, action: PayloadAction<Section>) => {
      state.section = action.payload;
    },
  },
});

export const {
  setSelectedShift,
  setSelectedCalendar,
  setSelectedDuration,
  setDate,
  setSelectedSection,
} = timeSlice.actions;

export default timeSlice.reducer;

// import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import moment from "moment";

// export const currentDay: number = Number(moment().format("DD"));
// export const currentMonth: string = moment().format("MMMM");
// export const currentMonthIndex: number = moment().month() + 1; // Adding 1 to get the correct month index
// export const currentYear: number = Number(moment().format("YYYY"));

// interface DateInfo {
//   day: number;
//   month: string;
//   currentMonth: number;
//   year: number;
// }

// interface TimeState {
//   selectedShift: string;
//   selectedCalendar: string;
//   selectedDuration: string;
//   date: DateInfo;
// }

// const initialState: TimeState = {
//   selectedShift: "all",
//   selectedCalendar: "month",
//   selectedDuration: "",
//   date: {
//     day: currentDay,
//     month: currentMonth,
//     currentMonth: currentMonthIndex,
//     year: currentYear,
//   },
// };

// const timeSlice = createSlice({
//   name: "time",
//   initialState,
//   reducers: {
//     setSelectedShift: (state, action: PayloadAction<string>) => {
//       state.selectedShift = action.payload;
//     },
//     setSelectedCalendar: (state, action: PayloadAction<string>) => {
//       state.selectedCalendar = action.payload;
//     },
//     setSelectedDuration: (state, action: PayloadAction<string>) => {
//       state.selectedDuration = action.payload;
//     },
//     setDate: (state, action: PayloadAction<Partial<DateInfo>>) => {
//       state.date = { ...state.date, ...action.payload };
//     },
//   },
// });

// export const {
//   setSelectedShift,
//   setSelectedCalendar,
//   setSelectedDuration,
//   setDate,
// } = timeSlice.actions;

// export default timeSlice.reducer;
