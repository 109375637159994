import { Button, Search } from "@heathmont/moon-core-tw";
import React, { useState } from "react";
import { ControlsPlus } from "@heathmont/moon-icons-tw";
import DropDown from "../../../Components/UI/DropDown";
import SwapDrawer from "./SwapDrawer";

const HeaderFilter = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const month = [
    { name: "January" },
    { name: "February" },
    { name: "March" },
    { name: "April" },
    { name: "May" },
    { name: "June" },
    { name: "July" },
    { name: "August" },
    { name: "September" },
    { name: "October" },
    { name: "November" },
    { name: "December" },
  ];
  const year = [
    { name: "2023" },
    { name: "2022" },
    { name: "2021" },
    { name: "2020" },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <div className="flex flex-wrap -mx-[7px] items-center">
        <div className="w-2/5 px-[7px]">
          <Search
            onChangeSearch={setSearch}
            onChangeOpen={setOpen}
            search={"search"}
            isOpen={open}
          >
            <Search.Input>
              <Search.Input.Icon />
              <Search.Input.Input className="text-moon-14 text-trunks" />
            </Search.Input>
            <Search.Transition>
              <Search.Result>{/* Search Results */}</Search.Result>
            </Search.Transition>
          </Search>
        </div>
        <div className="w-3/5 px-[7px]">
          <div className="flex flex-wrap gap-[14px]">
            <div className="flex-1">
              {/* <DropDown option="Month" data={month} /> */}
            </div>
            <div className="flex-1">
              {/* <DropDown option="Year" data={year} /> */}
            </div>
            <Button variant="tertiary" onClick={handleClick}>
              <ControlsPlus className="text-moon-24" />
              Apply Swap
            </Button>
          </div>
        </div>
      </div>
      <SwapDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={handleClose}
      />
    </>
  );
};

export default HeaderFilter;
