import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSpecificEmployeeDataForWeek } from "../../../../../services/schedule.service";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import moment from "moment";
import { IWeeklySchedule } from "../../../../../types/interfaces/IWeeklySchedule";
import {
  IMonthDropDown,
  IWeekDropDown,
  IYearDropDown,
} from "../../../../../types/interfaces/IDateDropDown.interface";
interface NurseSchedule {
  date: string;
  floor: string;
  totalNurses: number;
  nurses: number;
  CN: number;
  patients: number;
  shift: string;
}

interface Props {
  month: IMonthDropDown;
  year: IYearDropDown;
  week: IWeekDropDown;
}
const NurseWeeklyActivities: React.FC<Props> = ({ month, year, week }) => {
  // const [weekDates, setWeekDates] = useState<{ day: string; date: string }[]>(
  //   []
  // );
  // useEffect(() => {
  //   const getCurrentWeekDates = () => {
  //     const today = new Date();
  //     const dayOfWeek = today.getDay();

  //     const startDate = new Date(today);
  //     startDate.setDate(today.getDate() - dayOfWeek);

  //     const weekDates = [];
  //     const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  //     for (let i = 0; i < 7; i++) {
  //       const date = new Date(startDate);
  //       date.setDate(startDate.getDate() + i);
  //       weekDates.push({
  //         day: weekdays[date.getDay()],
  //         date: date.toISOString().split("T")[0],
  //       });
  //     }

  //     setWeekDates(weekDates);
  //   };
  //   getCurrentWeekDates();
  // }, []);

  // const nurseScheduleList = [
  //   {
  //     date: "2023-11-05",
  //     floor: "2nd",
  //     totalNurses: 7,
  //     nurses: 5,
  //     CN: 2,
  //     patients: 2,
  //     shift: "Day",
  //   },
  //   {
  //     date: "2023-11-06",
  //     floor: "2nd",
  //     totalNurses: 7,
  //     nurses: 5,
  //     CN: 2,
  //     patients: 2,
  //     shift: "Night",
  //   },
  //   {
  //     date: "2023-11-07",
  //     floor: "2nd",
  //     totalNurses: 7,
  //     nurses: 5,
  //     CN: 2,
  //     patients: 2,
  //     shift: "Day",
  //   },
  //   {
  //     date: "2023-11-08",
  //     floor: "2nd",
  //     totalNurses: 7,
  //     nurses: 5,
  //     CN: 2,
  //     patients: 2,
  //     shift: "Night",
  //   },
  // ];

  // const requestOffList: string[] = ["2023-08-03"];
  // const appliedLeaveList: string[] = ["2023-08-05"];

  // const hasRequestOffForDate = (date: string): boolean => {
  //   return requestOffList.includes(date);
  // };

  // const hasAppliedLeaveForDate = (date: string): boolean => {
  //   return appliedLeaveList.includes(date);
  // };

  // const getScheduleForDate = (
  //   date: string
  // ):
  //   | NurseSchedule
  //   | { date: string; requestOff?: boolean; appliedLeave?: boolean }
  //   | null
  //   | any => {
  //   const schedule = nurseScheduleList.find(
  //     (schedule) => schedule.date === date
  //   );
  //   if (schedule) {
  //     return schedule;
  //   } else if (hasRequestOffForDate(date)) {
  //     return { date: date, requestOff: true };
  //   } else if (hasAppliedLeaveForDate(date)) {
  //     return { date: date, appliedLeave: true };
  //   } else {
  //     return null;
  //   }
  // };

  ////////////////////////////////////

  const { selectedShift, date } = useSelector((state: RootState) => state.time);
  const [employeeWeeklySchedule, setEmployeeWeeklySchedule] =
    useState<IWeeklySchedule>(); // assign type
  // const weekNumber = date?.week; // Week number you want to get
  // const year = year?.year; // Year of the month
  // const month = month?.label; // Month (January is 1, February is 2, ..., December is 12)
  const shift =
    selectedShift === "sun"
      ? "day"
      : selectedShift === "moon"
      ? "night"
      : selectedShift;

  const getWeekDatesAndDayNames = (
    weekNumber: number,
    year: number,
    month: number
  ) => {
    const startDate = moment()
      .year(year)
      .month(month - 1)
      .date(1);
    // startDate.isoWeekday(1); // Setting to the first day of the week (Monday)
    startDate.isoWeekday(); //setting the first date of the month to the frst day of the week
    // Adjust to the specified week
    startDate.add(weekNumber - 1, "weeks");

    const weekDates = [];
    const dayNames = [];
    const daysInMonth = startDate.daysInMonth();

    for (let i = 0; i < 7; i++) {
      if (startDate.date() <= daysInMonth) {
        weekDates.push(startDate.clone());
        dayNames.push(startDate.format("ddd")); // Full day name (e.g., "Sunday")
      }
      startDate.add(1, "day");
    }

    return { weekDates, dayNames };
  };

  const { weekDates, dayNames } = getWeekDatesAndDayNames(
    +week.label,
    +year?.year,
    +month?.label
  );

  const fetchEmployeeWeeklyData = async () => {
    const res = await getSpecificEmployeeDataForWeek(
      shift,
      +week?.label,
      +month?.label,
      +year?.year
    );
    if (res?.res) {
      setEmployeeWeeklySchedule(res?.res as IWeeklySchedule);
    }
  };
  useEffect(() => {
    fetchEmployeeWeeklyData();
    // getWeekDatesAndDayNames(weekNumber, year, month);
  }, [selectedShift, month, year, week]);

  return (
    <>
      <div className="flex flex-1">
        {weekDates?.map((date, index: number) => (
          <div
            key={index}
            className={`basis-1/7 flex flex-col items-center justify-center flex-1`}
          >
            <div
              className={`h-[65px] py-[8px] px-[16px] border-r border-b border-beerus w-full 
              
              ${
                employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                  ?.isOnTimeOff
                  ? "bg-teal-50"
                  : // : getScheduleForDate(date)?.appliedLeave
                    // ? "bg-zinc-200"
                    ""
              }
              `}
            >
              <Link
                to="#"
                className={`text-sm font-semibold flex flex-col w-full items-start uppercase
                ${
                  employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                    ?.isOnTimeOff
                    ? "text-teal-400"
                    : //  : getScheduleForDate(date)?.appliedLeave
                      //  ? "text-raditz"
                      "text-gray-600"
                }
                   
                `}
              >
                {dayNames[index]}{" "}
                <span
                  className={` 
                  ${
                    employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                      ?.isOnTimeOff
                      ? "text-teal-400"
                      : // : getScheduleForDate(date)?.appliedLeave
                        // ? "text-raditz"
                        "text-blackcst"
                  }
                  text-moon-24`}
                >
                  {date.format("DD")}
                </span>
              </Link>
            </div>
            <div
              className={`py-4 px-3 w-full border-r border-beerus flex-1 flex flex-col ${
                employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                  ?.isOnTimeOff
                  ? "bg-teal-50"
                  : // : getScheduleForDate(date)?.appliedLeave
                    // ? "bg-zinc-200"
                    ""
              }`}
            >
              {employeeWeeklySchedule?.employeeSchedule?.shifts.length !== 0 ? (
                employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                  ?.isOnTimeOff ? (
                  <div className="flex flex-col mb-2 flex-1 justify-center text-center">
                    <span className="text-teal-400">RO</span>
                  </div>
                ) : employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                    ?.isOnLeave ? (
                  <div className="flex flex-col mb-2 flex-1 justify-center text-center">
                    <span className="text-raditz">AL</span>
                  </div>
                ) : employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                    ?.isDayShift ||
                  employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                    ?.isNightShift ? (
                  <ul key={index}>
                    {/* <li className="flex flex-col mb-2">
                        <span className="text-moon-16 text-gray-600">Floor:</span>
                        <h4 className="font-semibold text-moon-16 text-blackcst">
                          {getScheduleForDate(date)?.floor}
                        </h4>
                      </li> */}
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">Shift:</span>
                      {employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                        ?.isDayShift ? (
                        <span className="inline-flex items-center justify-center w-6 h-6 text-krillin bg-chichi-10 text-moon-14 rounded-md">
                          <Icon icon="uil:sun" />
                        </span>
                      ) : employeeWeeklySchedule?.employeeSchedule?.shifts[
                          index
                        ]?.isNightShift ? (
                        <span className="inline-flex items-center justify-center w-6 h-6 bg-beerus  text-moon-14 rounded-md">
                          <Icon icon="uil:moon" />
                        </span>
                      ) : null}
                    </li>
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">
                        Total Staff:
                      </span>
                      <h4 className="font-semibold text-moon-16 text-blackcst">
                        {
                          employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.totalEmployees
                        }
                      </h4>
                    </li>
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">
                        Nurses:
                      </span>
                      <h4 className="font-semibold text-moon-16 text-blackcst">
                        {
                          employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.totalNurses
                        }
                      </h4>
                    </li>
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">
                        Senior Nurses:
                      </span>
                      <h4 className="font-semibold text-moon-16 text-blackcst">
                        {
                          employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.totalSeniorNurses
                        }
                      </h4>
                    </li>
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">HCA:</span>
                      <h4 className="font-semibold text-moon-16 text-blackcst">
                        {
                          employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.totalHCAs
                        }
                      </h4>
                    </li>
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">
                        Patients:
                      </span>
                      <h4 className="font-semibold text-moon-16 text-blackcst">
                        {
                          employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.totalPatients
                        }
                      </h4>
                    </li>
                  </ul>
                ) : (
                  "NA"
                )
              ) : (
                <div
                  className="flex flex-col mb-2 flex-1 justify-center text-center"
                  key={index}
                >
                  <span className="opacity-30">No schedule</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default NurseWeeklyActivities;
