import React, { useState } from "react";
import {
  ControlsChevronRight,
  ControlsChevronLeft,
} from "@heathmont/moon-icons-tw";
import { IconButton } from "@heathmont/moon-core-tw";

const weekdays = ["M", "T", "W", "T", "F", "S", "S"];

const CalendarSwap: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedMonthDays, setSelectedMonthDays] = useState<{
    [month: number]: number[];
  }>({});
  const requestedOffDayAugust = 2; // August 2, 2023

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
    );
  };

  const handleDayClick = (day: number) => {
    const newSelectedMonthDays = {
      ...selectedMonthDays,
      [currentDate.getMonth()]: selectedMonthDays[currentDate.getMonth()]
        ? selectedMonthDays[currentDate.getMonth()]!.includes(day)
          ? selectedMonthDays[currentDate.getMonth()]!.filter((d) => d !== day)
          : [...selectedMonthDays[currentDate.getMonth()]!, day]
        : [day],
    };
    setSelectedMonthDays(newSelectedMonthDays);
  };

  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();
  const startDay =
    (new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay() +
      6) %
    7;

  const monthName = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();

  const selectedDaysForCurrentMonth =
    selectedMonthDays[currentDate.getMonth()] || [];

  return (
    <div className="flex items-center justify-center">
      <div className="w-full">
        <div className="bg-white rounded-[10px] shadow-shodowCst p-4">
          <div className="flex items-center justify-between">
            <IconButton
              variant="ghost"
              onClick={handlePrevMonth}
              className="font-semibold w-[40px] border-goku border shadow-none hover:shadow-none text-moon-18"
            >
              <ControlsChevronLeft />
            </IconButton>
            <h3 className="text-moon-12 text-bulma font-semibold text-center py-[5.62px]">
              {`${monthName} ${year}`}
            </h3>
            <IconButton
              variant="ghost"
              onClick={handleNextMonth}
              className="font-semibold w-[40px] border-goku border shadow-none hover:shadow-none text-moon-18"
            >
              <ControlsChevronRight />
            </IconButton>
          </div>

          <div className="grid grid-cols-7 gap-1">
            {weekdays.map((weekday) => (
              <p
                key={weekday}
                className="text-center text-moon-14 font-semibold text-gray-500 py-2 text-trunks"
              >
                {weekday}
              </p>
            ))}
            {Array.from({ length: startDay }, (_, index) => (
              <p
                key={`empty-${index}`}
                className="text-base text-center px-2 py-3"
              ></p>
            ))}
            {Array.from({ length: daysInMonth }, (_, index) => {
              const day = index + 1;
              const isSelected = selectedDaysForCurrentMonth.includes(day);
              const isRequestedOff =
                currentDate.getMonth() === 7 &&
                (day === requestedOffDayAugust - 1 || day === 5);
              return (
                <p
                  key={`day-${index}`}
                  onClick={() => handleDayClick(day)}
                  className={`text-base font-medium text-center px-2 py-2 w-full h-full rounded-[11.25px] border cursor-pointer ${(index + startDay) % 7 === 6 ? "" : ""
                    } ${isSelected
                      ? "bg-hit text-white"
                      : isRequestedOff
                        ? "border-hit text-hit"
                        : "border-[#EBEBEB] text-gray-600"
                    }`}
                >
                  {day}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarSwap;
