import React, { useEffect, useState } from "react";
import {
  ControlsChevronRight,
  ControlsChevronLeft,
} from "@heathmont/moon-icons-tw";
import { IconButton } from "@heathmont/moon-core-tw";
import { RootState, store } from "../../store/store";
import { useSelector } from "react-redux";
import moment from "moment";
import { setDate } from "../../features/Time Slice/timeSlice";

const weekdays: string[] = ["M", "T", "W", "T", "F", "S", "S"];
interface IRequest {
  monthName: string;
  leaves: ILeaves[];
}
interface ILeaves {
  date: string;
  _id: string;
  month: number;
}
interface Props {
  selectedDays: ILeaves[];
  month: number;
  year: number;
  setMonth?: (data: any) => any;
  setYear?: (data: any) => void;
  dateSelected?: any;
  clearAppliedDays?:()=>void;
}
const InlineCalendar: React.FC<Props> = ({
  selectedDays,
  month,
  year,
  setMonth,
  setYear,
  dateSelected,
  clearAppliedDays
}) => {
  // const [currentDate, setCurrentDate] = useState(new Date());

  // const getCurrentMonthDays = (currentMonth: any, currentYear: any) => {
  //   const firstDayOfMonth = new Date(currentYear, currentMonth);
  //   const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  //   const startDayOfWeek = firstDayOfMonth.getDay();
  //   const days = [];
  //   const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  //   const prevMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
  //   const prevMonthLastDay = new Date(
  //     prevMonthYear,
  //     prevMonth + 1,
  //     0,
  //   ).getDate();

  //   // Add days from previous month
  //   for (
  //     let i = prevMonthLastDay - startDayOfWeek;
  //     i <= prevMonthLastDay;
  //     i++
  //   ) {
  //     days.push({ day: i, currentMonth: false });
  //   }

  //   // Add days from current month
  //   for (
  //     let i = firstDayOfMonth.getDate();
  //     i <= lastDayOfMonth.getDate();
  //     i++
  //   ) {
  //     days.push({ day: i, currentMonth: true });
  //   }

  //   return days;
  // };
  const getCurrentMonthDays = (currentYear: any) => {
    const firstDayOfMonth = new Date(currentYear, month - 1, 1);
    const lastDayOfMonth = new Date(currentYear, month, 0);
    const startDayOfWeek = firstDayOfMonth.getDay(); // 0 for Sunday, 1 for Monday, etc.

    // Calculate the number of days from the previous month to include
    // based on the day of the week of the first day of the current month.
    let daysToIncludeFromPrevMonth =
      startDayOfWeek === 0 ? 6 : startDayOfWeek - 1;

    const days: any = [];
    const prevMonth = month === 1 ? 12 : month;
    const prevMonthYear = month === 1 ? currentYear - 1 : currentYear;
    const prevMonthLastDay = new Date(
      prevMonthYear,
      prevMonth - 1,
      0,
    ).getDate();

    // Add days from previous month
    for (
      let i = prevMonthLastDay - daysToIncludeFromPrevMonth + 1;
      i <= prevMonthLastDay;
      i++
    ) {
      days.push({
        day: null,
        currentMonth: false,
        date: new Date(prevMonthYear, prevMonth - 1, i),
      });
    }

    // Add days from the current month
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      days.push({
        day: i,
        currentMonth: true,
        date: new Date(currentYear, month - 1, i),
      });
    }

    return days;
  };
  const renderDaysOfWeek = () => {
    const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];

    return daysOfWeek.map((day, index) => (
      <div
        key={index}
        className="basis-1/7 px-2 py-3 flex justify-center text-moon-12 text-trunks"
      >
        <p className="text-moon-12 font-medium text-center text-gray-800 dark:text-gray-100">
          {day}
        </p>
      </div>
    ));
  };

  const handleDateClick = (dayObj: any) => {
    if (dateSelected) {
      dateSelected(dayObj);
    }
  };

  // const { date } = useSelector((state: RootState) => state.time);
  const renderCalendarDays = () => {
    const days = getCurrentMonthDays(year);
    console.log("DAys", days);
    return days?.map((dayObj: any, index: any) => {
      const request = selectedDays?.find(
        (req: any) =>
          moment(req?.date).format("D") == dayObj.day &&
          Number(moment(dayObj.date).format("M")) ==
            Number(moment(req?.date).format("M")),
      );

      let className = "text-moon-14";
      if (!dayObj.currentMonth) {
        className += " text-gray-300";
      } else if (request && request?.date === "Approved") {
        className += " bg-roshi text-white";
      } else if (request && request?.date) {
        className += dateSelected
          ? " bg-frieza text-white cursor-pointer"
          : " border-frieza border-[1px] border-solid text-frieza pointer-events-none";
        //                       : "border-[#EBEBEB] text-gray-600"";
      } else {
        className += ` text-gray-300  ${
          moment(dayObj?.date).isBefore(moment())
            ? "pointer-events-none opacity-50"
            : dateSelected
            ? "cursor-pointer"
            : "pointer-events-none"
        }`;
        // moment(dateStr).isAfter(moment())
      }

      return (
        <div
          key={index}
          className={`flex justify-center rounded-[11.25px] ${className} ${
            dayObj?.date ===
              moment(dayObj?.date, "MM/DD/YYYY").format("MM/DD/YYYY") &&
            dayObj?.currentMonth
              ? "bg-roshi text-white"
              : ""
          }`}
          onClick={() => {
            {
              if (dayObj?.currentMonth) {
                handleDateClick({
                  date: moment(dayObj?.date).format("MM/DD/YYYY"),
                  _id: null,
                  month: moment(dayObj?.date).format("M"),
                });
              }
            }
          }}
        >
          {dayObj?.currentMonth ? (
            <p className="text-base font-medium text-center px-2 py-3 w-full h-full rounded-[11.25px] ">
              {dayObj.day}
            </p>
          ) : (
            ""
          )}
        </div>
      );
    });
  };

  const getCurrentMonthName = () => {
    const currentYear = year;
    const currentMonth = month - 1;

    const monthName = new Date(currentYear, currentMonth).toLocaleString(
      "en-US",
      {
        month: "long",
      },
    );

    return `${monthName} ${currentYear}`;
  };

  const handlePrevMonth = () => {
    if (month <= 12 && month !== 1) {
      setMonth && setMonth(month - 1);
    } else if (month === 1) {
      setMonth && setMonth(12);
      setYear && setYear(year - 1);
    }
    clearAppliedDays && clearAppliedDays()

  };

  const handleNextMonth = () => {
    if (month < 12) {
      setMonth && setMonth(month + 1);
    } else if (month === 12) {
      setMonth && setMonth(1);
      setYear && setYear(year + 1);
    }
    clearAppliedDays && clearAppliedDays()
  };

  return (
    <div className="flex items-center justify-center">
      <div className="w-full">
        <div className="bg-white rounded-[10px] shadow-shodowCst p-4">
          <div className="flex items-center justify-between">
            {dateSelected ? (
              <IconButton
                variant="ghost"
                onClick={handlePrevMonth}
                className=" font-semibold d w-[40px]  border-goku border shadow-none hover:shadow-none text-moon-18"
              >
                <ControlsChevronLeft />
              </IconButton>
            ) : (
              <div className=" font-semibold d w-[40px]  border-goku  shadow-none hover:shadow-none text-moon-18"></div>
            )}
            <h3 className="text-moon-12 text-bulma font-semibold text-center py-[5.62px] ">
              {getCurrentMonthName()}
            </h3>
            {dateSelected ? (
              <IconButton
                variant="ghost"
                onClick={handleNextMonth}
                className="font-semibold w-[40px]  border-goku border shadow-none hover:shadow-none text-moon-18"
              >
                <ControlsChevronRight />
              </IconButton>
            ) : (
              <div className="font-semibold w-[40px]  border-goku  shadow-none hover:shadow-none text-moon-18"></div>
            )}{" "}
          </div>

          <div className="flex flex-wrap">{renderDaysOfWeek()}</div>

          <div className="grid grid-cols-7 gap-1">{renderCalendarDays()}</div>
        </div>
      </div>
    </div>
  );
};

export default InlineCalendar;

// {
//   const [currentDate, setCurrentDate] = useState(new Date());
//   const [selectedMonthDays, setSelectedMonthDays] = useState<{
//     [month: number]: number[];
//   }>({});
//   const requestedOffDayAugust = 2; // August 2, 2023

//   const handlePrevMonth = () => {
//     setCurrentDate(
//       new Date(currentDate.getFullYear(), currentDate.getMonth() - 1),
//     );
//   };

//   const handleNextMonth = () => {
//     setCurrentDate(
//       new Date(currentDate.getFullYear(), currentDate.getMonth() + 1),
//     );
//   };

//   const handleDayClick = (day: number) => {
//     const newSelectedMonthDays = {
//       ...selectedMonthDays,
//       [currentDate.getMonth()]: selectedMonthDays[currentDate.getMonth()]
//         ? selectedMonthDays[currentDate.getMonth()]!.includes(day)
//           ? selectedMonthDays[currentDate.getMonth()]!.filter((d) => d !== day)
//           : [...selectedMonthDays[currentDate.getMonth()]!, day]
//         : [day],
//     };
//     setSelectedMonthDays(newSelectedMonthDays);
//   };

//   const daysInMonth = new Date(
//     currentDate.getFullYear(),
//     currentDate.getMonth() + 1,
//     0,
//   ).getDate();
//   const startDay =
//     (new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay() +
//       6) %
//     7;

//   const monthName = currentDate.toLocaleString("default", { month: "long" });
//   const year = currentDate.getFullYear();

//   const selectedDaysForCurrentMonth = selectedDays?.leaves;
//   // selectedMonthDays[currentDate.getMonth()] || [];

//   return (
//     <div className="flex items-center justify-center">
//       <div className="w-full">
//         <div className="bg-white rounded-[10px] shadow-shodowCst p-4">
//           <div className="flex items-center justify-between">
//             <IconButton
//               variant="ghost"
//               onClick={handlePrevMonth}
//               className="font-semibold w-[40px] border-goku border shadow-none hover:shadow-none text-moon-18"
//             >
//               <ControlsChevronLeft />
//             </IconButton>
//             <h3 className="text-moon-12 text-bulma font-semibold text-center py-[5.62px]">
//               {`${monthName} ${year}`}
//             </h3>
//             <IconButton
//               variant="ghost"
//               onClick={handleNextMonth}
//               className="font-semibold w-[40px] border-goku border shadow-none hover:shadow-none text-moon-18"
//             >
//               <ControlsChevronRight />
//             </IconButton>
//           </div>

//           <div className="grid grid-cols-7 gap-1">
//             {weekdays.map((weekday) => (
//               <p
//                 key={weekday}
//                 className="text-center text-moon-14 font-semibold text-gray-500 py-2 text-trunks"
//               >
//                 {weekday}
//               </p>
//             ))}
//             {Array.from({ length: startDay }, (_, index) => (
//               <p
//                 key={`empty-${index}`}
//                 className="text-base text-center px-2 py-3"
//               ></p>
//             ))}
//             {Array.from({ length: daysInMonth }, (_, index) => {
//               const day = index + 1;
//               const isSelected = selectedDaysForCurrentMonth.includes(day);
//               const isRequestedOff =
//                 currentDate.getMonth() === 7 &&
//                 (day === requestedOffDayAugust - 1 || day === 5);
//               return (
//                 <p
//                   key={`day-${index}`}
//                   onClick={() => handleDayClick(day)}
//                   className={`text-base font-medium text-center px-2 py-2 w-full h-full rounded-[11.25px] border cursor-pointer ${
//                     (index + startDay) % 7 === 6 ? "" : ""
//                   } ${
//                     isSelected
//                       ? "bg-frieza text-white"
//                       : isRequestedOff
//                       ? "borderfrieza text-frieza"
//                       : "border-[#EBEBEB] text-gray-600"
//                   }`}
//                 >
//                   {day}
//                 </p>
//               );
//             })}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
