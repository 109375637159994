import { jwtDecode } from "jwt-decode";
import { removeUser, setAuthUser } from "../features/Auth Slice/authSlice";
import { store } from "../store/store";
import { create } from "apisauce";
import spinnerSvc from "../utils/spinner-service";
import { INewPasswordRequest } from "../types/interfaces/INewPasswordRequest.interface";
import { ITokens } from "../types/interfaces/ITokens.interface";
import { IEmail } from "../types/interfaces/IEmail.interface";
import { IFormValues } from "../types/interfaces/IFormValues.interface";
import { ITokenVerfication } from "../types/interfaces/ITokenVerfication.interface";
import { notificationSocket } from "../utils/notification.socket.service";

export const authApi = create({
  baseURL: process.env.REACT_APP_API_URL_STAGING,
  headers: { Accept: "application/json" },
});

authApi.axiosInstance.interceptors.request.use(
  async (config) => {
    spinnerSvc.start();
    return config;
  },
  (err) => console.error(err),
);
authApi.axiosInstance.interceptors.response.use(
  (response) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
  },
);
export const authLogin = async (loginRequest: IFormValues) => {
  try {
    const response: any = await authApi.post("/employee/signIn", loginRequest);
    if (response?.data) {
      const tokenBase64: any = response?.data?.accessToken;
      const tokenData: any = jwtDecode(tokenBase64);
      sessionStorage.setItem("user", JSON.stringify(tokenData));
      store.dispatch(setAuthUser(tokenData));
      storeTokens(response?.data);
      // notificationSocket?.initializeConnection(
      //   tokenData?.id,
      //   tokenData?.designation
      // );
    }
    return response?.data;
  } catch (error) {
    return { err: error };
  }
};

export const refreshToken = async () => {
  const rtToken = sessionStorage.getItem("rtToken");
  try {
    const { data, ok }: any = await authApi.get(
      "employee/getNewTokens",
      undefined,
      {
        headers: { Authorization: `Bearer ${rtToken}` },
      },
    );
    if (ok) {
      storeTokens(data);
      return data?.accessToken;
    }

    throw new Error("expired token");
  } catch (error) {
    return { err: error };
  }
};
//user authApi in forget password instead of "api" service
export const resetPassword = async (email: string) => {
  try {
    const response = await authApi.post("/admin/forgetPassword", {
      email: email,
    });
    if (response && response.ok) {
      return { mailSent: true };
    } else {
      throw new Error("No such user found");
    }
  } catch (error) {
    return { err: error };
  }
};

export const verifyResetPassword = async (params: ITokenVerfication) => {
  try {
    const response = await authApi.post(`/admin/verifyResetToken`, params);
    if (response && response?.ok) {
      return { verified: true };
    } else {
      throw new Error("No such user found");
    }
  } catch (error) {
    return { err: error };
  }
};

export const changePassword = async (params: INewPasswordRequest) => {
  try {
    const response = await authApi.put(`/admin/changePassword`, params);
    if (response && response?.ok) {
      return { changedSuccess: true };
    } else {
      throw new Error("There is an error in password change");
    }
  } catch (error) {
    return { err: error };
  }
};

export const logout = () => {
  // sessionStorage.removeItem("acToken");
  // sessionStorage.removeItem("rtToken");
  store.dispatch(removeUser());
  notificationSocket?.disconnectSocket();
};

export const storeTokens = (tokens: ITokens) => {
  const { refreshToken, accessToken } = tokens;
  sessionStorage.setItem("acToken", accessToken);
  sessionStorage.setItem("rtToken", refreshToken);
};

// import createApi from "../utils/axios";
// import { METHODS } from "../enums/axios.enums";
// import { ILoginRequest } from "../types/interfaces/ILoginRequest.interface";
// import { ILoginResponse } from "../types/interfaces/ILoginRespose.interface";
// import { IRefreshTokenResponse } from "../types/interfaces/IRefreshTokenResponse.interface";
// import jwtDecode from "jwt-decode";
// import { setAuthUser } from "../features/authSlice";
// import { IUserData } from "../types/interfaces/IUserData.interface";
// import { useDispatch } from "react-redux";
// import { store } from "../store/store";
// const authApi = createApi("employee");

// export const authLogin = async (
//   loginRequest: ILoginRequest,
// ): Promise<ILoginResponse> => {
//   const { data } = await authApi({
//     url: "/signIn",
//     method: METHODS.POST,
//     data: loginRequest,
//   });
//   if (data) {
//     const tokenBase64 = data?.accessToken;
//     const tokenData = jwtDecode(tokenBase64);
//     sessionStorage.setItem("user", JSON.stringify(tokenData));
//     store.dispatch(setAuthUser(tokenData));
//     storeTokens(data);
//   }
//       return data;

// };

// export const refreshToken = async (): Promise<IRefreshTokenResponse> => {
//   const refreshToken = sessionStorage.getItem("rtToken");

//   const { data } = await authApi({
//     url: "/getNewTokens",
//     method: METHODS.GET,
//     headers: { Authorization: `Bearer ${refreshToken}` },
//   });

//   storeTokens(data);
//   return data?.accessToken;
// };

// export const logout = () => {
//   sessionStorage.removeItem("acToken");
//   sessionStorage.removeItem("rtToken");
// };

// const storeTokens = (tokens: any) => {
//   const { refreshToken, accessToken } = tokens;
//   sessionStorage.setItem("acToken", accessToken);
//   sessionStorage.setItem("rtToken", refreshToken);
// };
