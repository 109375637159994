import React, { useEffect, useState } from "react";
import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { ControlsChevronDown } from "@heathmont/moon-icons-tw";

interface Props {
  value: any;
  options: any;
  setOptions: any;
  field: any;
}

const DropDown: React.FC<Props> = ({ value, options, setOptions, field }) => {
  const [option, setOption] = useState<any>(value);

  useEffect(() => {
    setOption(value);
  }, [value]);
  return (
    <Dropdown
      value={option}
      onChange={(option) => {
        setOption(option);
        setOptions(option);
      }}
    >
      <Dropdown.Trigger>
        <button className="w-full min-w-[173px] border-beerus border px-3 py-2 rounded-lg font-normal text-moon-14 justify-between inline-flex items-center bg-white">
          {option?.name}
          {/* {option} */}
          <i>
            <ControlsChevronDown className="text-moon-18" />
          </i>
        </button>
      </Dropdown.Trigger>
      <Dropdown.Options className="dropdownoption min-w-[100px]">
        {options?.map((option: any, index: any) => (
          <Dropdown.Option value={option} key={index}>
            {({ selected, active }) => (
              <MenuItem
                isActive={active}
                isSelected={selected}
                className={`${
                  selected || active ? "bg-goku text-cyan-500" : "text-gray-900"
                } text-moon-14 group flex w-full items-center rounded-md px-2 py-2 focus:bg-goku focus:text-hit hover:bg-goku hover:text-hit`}
                value={option?.value}
              >
                {option?.[field]}
              </MenuItem>
            )}
          </Dropdown.Option>
        ))}
      </Dropdown.Options>
    </Dropdown>
  );
};

export default DropDown;
// import React, { useEffect, useState } from "react";
// import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
// import { ControlsChevronDown } from "@heathmont/moon-icons-tw";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   setDate,
//   setSelectedDuration,
// } from "../../../features/nurse-manager/Time Slice/timeSlice";
// import { RootState } from "../../../store/store";
// const DropDown = (props: any) => {
//   //const [option, setOption] = useState(props.option);
//   const { selectedDuration, selectedCalendar } = useSelector(
//     (state: RootState) => state.time
//   );
//   const dispatch = useDispatch();
//   const { data, option } = props;
//   useEffect(() => {
//     dispatch(setSelectedDuration(option));
//   }, []);

//   // check type from redux configuration
//   const handleOptionChange = (selectedItem: any) => {
//     dispatch(setSelectedDuration(selectedItem.name));
//     if (selectedCalendar === "month") {
//       dispatch(
//         setDate({
//           month: selectedItem.monthName,
//           currentMonth: selectedItem?.label,
//         })
//       );
//     }
//   };

//   //

//   return (
//     <Dropdown
//       value={selectedDuration}
//       onChange={(item: any) => dispatch(setSelectedDuration(item?.name))}
//     >
//       <Dropdown.Trigger>
//         <button className="w-full border-beerus border px-3 py-2 rounded-lg font-normal text-moon-14 justify-between inline-flex items-center bg-white">
//           {selectedDuration}
//           <i>
//             <ControlsChevronDown className="text-moon-18" />
//           </i>
//         </button>
//       </Dropdown.Trigger>

//       <Dropdown.Options>
//         {data.map((item: any, index: any) => (
//           <Dropdown.Option value={item} key={index}>
//             {({ selected, active }) => (
//               <MenuItem
//                 isActive={active}
//                 isSelected={selected}
//                 onClick={() => handleOptionChange(item)}
//                 className={`${
//                   selected || active ? "bg-goku text-cyan-500" : "text-gray-900"
//                 } text-moon-14 group flex w-full items-center rounded-md px-2 py-2 focus:bg-goku focus:text-hit hover:bg-goku hover:text-hit`}
//               >
//                 {item.name}
//               </MenuItem>
//             )}
//           </Dropdown.Option>
//         ))}
//       </Dropdown.Options>
//     </Dropdown>
//   );
// };

// export default DropDown;

// import React, { useEffect, useState } from "react";
// import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
// import { ControlsChevronDown } from "@heathmont/moon-icons-tw";

// const DropDown = (props: any) => {
//   const [selectedDuration, setSelectedDuration] = useState(props.option);

//   useEffect(() => {
//     setSelectedDuration(props.option);
//   }, [props.option]);

//   const handleOptionChange = (selectedItem: any) => {
//     setSelectedDuration(selectedItem.name);
//     // props.onOptionChange(selectedItem); // Pass the selected item back to the parent component if needed
//   };

//   return (
//     <Dropdown
//       value={selectedDuration}
//       onChange={(item) => handleOptionChange(item)}
//     >
//       <Dropdown.Trigger>
//         <button className="w-full border-beerus border px-3 py-2 rounded-lg font-normal text-moon-14 justify-between inline-flex items-center bg-white">
//           {selectedDuration}
//           <i>
//             <ControlsChevronDown className="text-moon-18" />
//           </i>
//         </button>
//       </Dropdown.Trigger>

//       <Dropdown.Options>
//         {props.data.map((item: any, index: any) => (
//           <Dropdown.Option value={item} key={index}>
//             {({ selected, active }) => (
//               <MenuItem
//                 isActive={active}
//                 isSelected={selected}
//                 onClick={() => handleOptionChange(item)}
//                 className={`${
//                   selected || active ? "bg-goku text-cyan-500" : "text-gray-900"
//                 } text-moon-14 group flex w-full items-center rounded-md px-2 py-2 focus:bg-goku focus:text-hit hover:bg-goku hover:text-hit`}
//               >
//                 {item.name}
//               </MenuItem>
//             )}
//           </Dropdown.Option>
//         ))}
//       </Dropdown.Options>
//     </Dropdown>
//   );
// };

// export default DropDown;
