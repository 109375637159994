import { Avatar } from "@heathmont/moon-core-tw";
import { Other3Dots } from "@heathmont/moon-icons-tw";
import { Icon } from "@iconify/react";
import React from "react";

const CardShiftList = ({ img, name, date, time, status }: any) => {
  return (
    <div className="flex flex-wrap items-center bg-white rounded-[8px] overflow-hidden mb-[4px]">
      <div className="flex-[0_0_246px]  p-[17px_16px]">
        <div className="flex flex-wrap justify-between gap-[8px] items-center">
          <span className="inline-flex rounded-full shadow-md border-[2px] border-white">
            <Avatar
              size="md"
              className={`rounded-full  overflow-hidden`}
              imageUrl={img}
            />
          </span>
          <p className="text-moon-16 font-semibold flex-1 line-clamp-1">
            {name}
          </p>
        </div>
      </div>
      <div className="flex-[0_0_246px]  p-[17px_16px]">
        <p>{date}</p>
      </div>
      <div className="flex-[0_0_246px]  p-[17px_16px]">
        <div
          className={`inline-flex items-center gap-[5px] font-semibold text-moon-16 text-${time === "Day" ? "krillin" : "#272D36"
            }`}
        >
          {time === "Day" ? (
            <span className="text-moon-18">
              <Icon icon="uil:sun" />
            </span>
          ) : (
            <span className="text-moon-18">
              <Icon icon="uil:moon" />
            </span>
          )}
          {time}
        </div>
      </div>
      <div className="flex-1 flex items-center justify-between p-[17px_16px]">
        <div
          className={`bg-${status === "Approved"
              ? "roshi"
              : status === "Rejected"
                ? "dodoria"
                : "frieza"
            }-10 inline-flex items-center justify-between rounded-[8px] p-[8px_16px] text-${status === "Approved"
              ? "roshi"
              : status === "Rejected"
                ? "dodoria"
                : "frieza"
            } font-semibold text-moon-16`}
        >
          {status}
        </div>
        <Other3Dots />
      </div>
    </div>
  );
};

export default CardShiftList;
