import React, { useEffect, useState } from "react";
import HeaderFilter from "./widgets/HeaderFilter";
import PageTitle from "../../Components/layouts/header/page-title";
import FilterSideBar from "../../Components/UI/FilterSideBar";
import TimeOffListing from "./widgets/TimeOffListing";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getRequests } from "../../services/employee.service";
import Table from "../../Components/View Table/NurseManagerTable";
import InlineCalendar from "../../Components/UI/InlineCalendar";
import { IYearDropDown } from "../../types/interfaces/IDateDropDown.interface";

type MenuItemData = any;

interface IRequestOff {
  leaves: IRequest[];
}
interface IRequest {
  monthName: string;
  leaves: ILeaves[];
}
interface ILeaves {
  date: string;
  _id: string;
  month: number;
}
const TimeOff = () => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [reqTimeOffViewRes, setReqTimeOffViewRes] = useState<IRequestOff>();

  const columnHelper = createColumnHelper<IRequest>();
  const columns = [
    columnHelper.accessor("monthName", {
      enableSorting: false,
      header: () => "Month",
      cell: (info) => (
        <div className="flex-1 text-moon-16">
          <span className="">{info.getValue()}</span>
        </div>
      ),
    }),

    columnHelper.accessor((row) => row?.leaves, {
      enableSorting: false,
      id: "leaves",
      header: "Requested",
      cell: (info) => (
        <div className="flex-[0_0_217px] text-moon-16">
          <h4 className="block">{info?.row?.original?.leaves?.length}</h4>
        </div>
      ),
    }),
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = React.useState(() => []);
  // const { date } = useSelector((state: RootState) => state.time);
  const [month, setMonth] = useState<number>();
  const [fetchAgain, setFetchAgain] = useState<boolean>(false);
  const [year, setYear] = useState({
    name: moment().format("YYYY"),
    year: moment().format("YYYY"),
    label: "year",
  });

  const [sidebarData, setSidebarData] = useState<MenuItemData[]>([
    {
      id: 1,
      label: "Status",
      isOpen: true,
      subItems: [
        { id: 1, label: "All", isSelected: false },
        { id: 2, label: "Requested", isSelected: false },
        { id: 3, label: "Approved", isSelected: false },
        { id: 4, label: "Rejected", isSelected: false },
      ],
    },
    {
      id: 2,
      label: "Year",
      isOpen: true,
      subItems: [
        { id: 5, label: "2023", isSelected: false },
        { id: 6, label: "2022", isSelected: false },
        { id: 7, label: "2021", isSelected: false },
        { id: 8, label: "2020", isSelected: false },
      ],
    },
  ]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    debugTable: true,
    getSortedRowModel: getSortedRowModel(),
  });

  const timeOffData = {
    2023: [
      { month: "October", leaves: 7, status: "Requested" },
      { month: "September", leaves: 12, status: "Approved" },
      { month: "July", leaves: 2, status: "Approved" },
      { month: "April", leaves: 8, status: "Approved" },
    ],
    2022: [
      { month: "October", leaves: 7, status: "Rejected" },
      { month: "September", leaves: 12, status: "Approved" },
    ],
  };

  const handleSidebarDataUpdate = (data: MenuItemData[]) => {
    setSidebarData(data);
  };

  const fetchRequest = async () => {
    const res: any = await getRequests(+year?.year);
    if (res?.res) {
      setData(res?.res?.leaves);
      setReqTimeOffViewRes(res?.res);

      // if(res?.re?.leaves)
      setSelectedDays({ ...res?.res?.leaves[0] });
      const getMonthIndex = moment()
        .month(
          res?.res?.leaves[0]?.monthName ? res?.res?.leaves[0]?.monthName : "",
        )
        .format("M");
      setMonth(Number(getMonthIndex));
    }
  };

  useEffect(() => {
    fetchRequest();
  }, [year, fetchAgain]);
  const handleRowClick = (row: any) => {
    const selectRow: any = reqTimeOffViewRes?.leaves.find(
      (reqTimeOff: IRequest) =>
        reqTimeOff?.monthName === row?.original?.monthName,
    );
    const getMonthIndex = moment()?.month(selectRow?.monthName).format("M");
    setMonth(Number(getMonthIndex));
    setSelectedDays(selectRow);
  };
  const selectedMonthName = moment()
    .month(Number(month) - 1)
    .format("MMMM");
  const [selectedDays, setSelectedDays] = useState<IRequest>();

  return (
    <>
      <div className="bg-[#f8f8f8]">
        <PageTitle
          title="Time-off"
          subtitle="Apply and see past time-off requests"
        />
        <div className="flex flex-wrap">
          <div className="w-1/4">
            <div className="border-r border-beerus bg-white shadow-[0_8px_24px_-6px_rgba(0,0,0,0.08)]">
              <div className="bg-white pt-[30px] p-[20px]">
                <h3 className="text-moon-20 font-semibold"> Month Calendar</h3>
                <p className="text-gray-600 text-moon-14 mb-[15px]">
                  You are seeing the time off days for {selectedMonthName}
                </p>
                <div className="bg-gohan flex flex-col gap-2 rounded-moon-s-lg">
                  <div className="px-[10px] h-[calc(100vh-284px)]">
                    <div className="flex-1">
                      <InlineCalendar
                        month={month as number}
                        selectedDays={selectedDays?.leaves as ILeaves[]}
                        year={
                          reqTimeOffViewRes?.leaves.length === 0
                            ? +moment().format("YYYY")
                            : +year?.year
                        }
                        // selectedDays={reqTimeOffViewRes?.leaves}
                        // onDaySelect={handleDaySelect}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-3/4">
            <div className="p-[20px_30px]">
              <HeaderFilter
                yearDropDown={year}
                setYearDropDown={setYear}
                setFetchAgain={setFetchAgain}
                fetchAgain={fetchAgain}
              />
              <div className="applytime p-[20px_0px] h-[calc(100vh-280px)]">
                {/* <TimeOffListing timeOffData={timeOffData} /> */}
                {!isLoading && (
                  <Table
                    table={table}
                    tableData={reqTimeOffViewRes as IRequestOff}
                    // handlePageChange={fetchRequestsOfEmployees}
                    handleRowClick={handleRowClick} // Add this prop
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeOff;
