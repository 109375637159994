import React from "react";
import TrackingTabs from "./TrackingTabs";

interface Props {
  title: string;
  setSelectedFilter: (designation: string) => void;
}
const TrackingHeader: React.FC<Props> = ({ title, setSelectedFilter }) => {
  return (
    <div className="bg-goku p-[8px] rounded-[8px] flex items-center">
      <h3 className="text-moon-14 font-semibold">{title}</h3>

      <div className="ml-auto">
        <TrackingTabs setSelectedFilter={setSelectedFilter} />
      </div>
    </div>
  );
};

export default TrackingHeader;
