import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ScheduleNurseDrawer from "../drawer/schedule-drawer";
import { Icon } from "@iconify/react";
import axios from "axios";
import moment from "moment";
import {
  IEmployeeData,
  IEmployeeDataResponse,
  IShift,
} from "../../../../../types/interfaces/IMonthSchedule.interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../../../store/store";
import { setMonthSchedule } from "../../../../../features/Schedule/Month/MonthScheduleSlice";
import { current } from "@reduxjs/toolkit";
import { ICalendarWeekday } from "../../../../../types/interfaces/IMonthCalendar.interface";
import {
  getDayInfo,
  getSpecificEmployeeDataForMonth,
} from "../../../../../services/schedule.service";
import { MonthlySchedulerProps } from "../../../../../types/interfaces/IMonthScheduleProps.interface";
import SimpleBar from "simplebar-react";
import WeekDaysTab from "../../../../../Components/Calendar/full-month-view/WeekDays";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../../types/interfaces/IDateDropDown.interface";
import { ISpecificDayRecord } from "../../../../../types/interfaces/ISpecificDayRecord";
import { IDayInfo } from "../../../../../types/interfaces/IDayInfo";
interface DayInfo {
  date: string; // Assuming date is in ISO format
  allocation: IShift;
  isCurrentMonth: boolean;
}

// interface SpecificDayRecord {
//   month: number;
//   monthName: string;
//   year: number;
//   scheduledBy: ScheduledBy;
//   dayNumber: number;
//   day: string;
//   dayShift: boolean;
//   nightShift: boolean;
//   status: string;
//   capacity: number;
//   utilization: string;
//   numberOfPatients: number;
//   criticalPatients: number;
//   employees: Employee[];
// }
// interface Employee {
//   employeeId: string;
//   employeeName: string;
//   designation: string;
//   shiftsSpent: number;
//   shiftsAllocated: number;
//   hoursSpent: number;
//   hoursAllocated: number;
// }
// interface ScheduledBy {
//   id: string;
//   firstName: string;
//   lastName: string;
// }
const weekDays = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];

interface Props {
  month: IMonthDropDown;
  year: IYearDropDown;
}
export const MonthCalender: React.FC<Props> = ({ month, year }) => {
  const { selectedShift } = useSelector((state: RootState) => state?.time);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<IEmployeeData>(
    {} as IEmployeeData
  );
  const [specificDayRecord, setSpecificDayRecord] = useState<IDayInfo>();

  const fetchDayInfo = async (day: number) => {
    const res = await getDayInfo(day, +month.label, +year?.year);
    if (res?.res) {
      setSpecificDayRecord(res?.res as IDayInfo);
    }
  };
  const handleClick = (dayInfo: IShift) => {
    if (dayInfo?.isNightShift || dayInfo?.isDayShift) {
      fetchDayInfo(moment(dayInfo?.date).date());
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const fetchMonthlySchedule = async () => {
    const res: any = await getSpecificEmployeeDataForMonth(
      +month?.label,
      +year?.year
    );
    if (res?.res) {
      setEmployeeData(res?.res?.employeeInfo);
    }
  };

  // Get the current date
  const currentDate = new Date();
  // Get the year of the current date
  const currentYear = currentDate.getFullYear();
  // Get the month of the current date (0-indexed, January is 0)
  const currentMonth = currentDate.getMonth();
  // Get the current day of the month
  const currentDay = currentDate.getDate();
  // Calculate the number of days in the current month
  const daysInCurrentMonth = new Date(
    currentYear,
    currentMonth + 1,
    0
  ).getDate();

  // Calculate the first day of the month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

  // Calculate the weekday (0 for Sunday, 1 for Monday, ..., 6 for Saturday) of the first day of the month
  const firstDayOfWeek = firstDayOfMonth.getDay();

  // Calculate the number of days to display from the previous month
  const daysFromPreviousMonth = 6;

  // array of weekdays, starting from Sunday and wrapping around to the next Sunday if needed
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Rearrange the weekdays based on the first day of the month
  const rearrangedWeekdays = [
    ...weekdays.slice(7 - daysFromPreviousMonth), // Days from the previous month
    ...weekdays.slice(0, 7 - daysFromPreviousMonth), // Days from the current month
  ];

  // const startDate = 1; // Starting date of the month
  // const endDate = daysInCurrentMonth; // Ending date of the month

  // const startDayOffset =
  //   (new Date(currentYear, currentMonth, 1).getDay() + 1) % 7;
  // const endDayOffset =
  //   7 - ((new Date(currentYear, currentMonth, endDate).getDay() + 1) % 7);

  /**
   * Generates a calendar for the given year and month, with allocation data.
   *
   * @param year - The year of the calendar.
   * @param month - The month of the calendar (0-11).
   * @param allocationData - The allocation data for the calendar.
   * @returns The generated calendar.
   */
  const generateCalendar = (
    year: number,
    month: number,
    allocationData: IShift[]
  ) => {
    const calendar: any = [];

    // Get the first day of the month
    const firstDay = new Date(year, month, 1);

    // Get the last day of the month
    const lastDay = new Date(year, month + 1, 0);

    // Get the start day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    let startDayOfWeek = firstDay.getDay();

    // Adjust startDayOfWeek to consider Monday as the first day
    startDayOfWeek = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1;

    let currentDate = new Date(firstDay);

    // Move back to the previous Monday if the month doesn't start on a Monday
    currentDate.setDate(currentDate.getDate() - startDayOfWeek);

    while (currentDate <= lastDay) {
      const week: any = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(currentDate);
        const isCurrentMonth = date.getMonth() === month;

        // Find allocation data for the current date
        const formattedDate = moment(date).format("YYYY-MM-DD");
        const allocation = allocationData?.find((data) => {
          if (moment(data.date).format("YYYY-MM-DD") === formattedDate) {
            return data;
          } else {
            return undefined;
          }
        });

        week.push({
          date,
          isCurrentMonth,
          allocation,
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      calendar.push(week);
    }

    return calendar;
  };

  /**
   * Renders the dates of a calendar.
   *
   * @param calendar - The calendar to render.
   * @returns The rendered calendar dates.
   */
  // const renderMonthDates = (calendar: any): any => {

  //   return calendar.map((week: any) =>
  //     week.map((data: ICalendarWeekday, index: any) => (
  //       <div
  //         key={index}
  //         onClick={handleClick}
  //         className={`basis-1/7 p-[12px] border border-beerus h-[110px] py-2 text-[14px] border-r order-s-0 justify-center inline-flex items-center ${
  //           data?.allocation?.isOnLeave
  //             ? "bg-zinc-200 text-raditz"
  //             : data?.allocation?.isDayShift || data?.allocation?.isNightShift
  //             ? ""
  //             : data?.allocation?.isOnTimeOff
  //             ? "bg-teal-50 text-teal-400"
  //             : ""
  //         } ${data?.allocation?.dayNumber === 20 ? "highlighted" : ""} ${
  //           data.isCurrentMonth ? "" : "opacity-40"
  //         }`}
  //       >
  //         <div className="w-full h-full flex flex-col">
  //           <div className="flex items-center justify-between">
  //             {data?.allocation?.dayNumber
  //               ? data?.allocation.dayNumber
  //               : data.date.getDate()}{" "}
  //             {/* Convert date to string */}
  //             {data?.allocation?.isOnLeave ? (
  //               ""
  //             ) : data?.allocation?.isDayShift ? (
  //               <span className="inline-flex items-center justify-center w-6 h-6 text-krillin bg-chichi-10 text-moon-14 rounded-md">
  //                 <Icon icon="uil:sun" />
  //               </span>
  //             ) : data?.allocation?.isNightShift ? (
  //               <span className="inline-flex items-center justify-center w-6 h-6 bg-gray-100 text-moon-14 rounded-md">
  //                 {/* Replace this with the night shift icon */}
  //                 <Icon icon="uil:moon" />
  //               </span>
  //             ) : data?.allocation?.isOnTimeOff ? (
  //               ""
  //             ) : null}
  //           </div>
  //           <div className="text-center flex-1 w-full flex items-center justify-center pb-[21px] ">
  //             {data?.allocation?.isDayShift ||
  //             data?.allocation?.isNightShift ? (
  //               ""
  //             ) : data?.allocation?.isOnLeave ? (
  //               <span className="font-semibold">
  //                 {data?.allocation?.leaveType}
  //               </span>
  //             ) : data?.allocation?.isOnTimeOff ? (
  //               <span className="font-semibold">RO</span>
  //             ) : null}
  //           </div>
  //         </div>
  //       </div>
  //     )),
  //   );
  // };

  useEffect(() => {
    if (month || year) {
      fetchMonthlySchedule();
    }
  }, [month, year]);

  const calendarData = generateCalendar(
    +year?.year,
    +month?.label - 1,
    employeeData?.shifts
  );

  return (
    <>
      <>
        {/* <SimpleBar
        className="overflow-y-auto"
        style={{ height: "calc(100vh - 440px)" }}
      > */}
        {calendarData?.map((week: any, weekIndex: number) => (
          <div key={weekIndex} className="flex flex-wrap">
            {week?.map(
              (
                dayInfo: DayInfo,
                dayIndex: number // dayInfo==> allocation :{} //object //date//isCurrentMonth
              ) => (
                <div
                  key={dayIndex}
                  onClick={() => {
                    handleClick(dayInfo?.allocation);
                  }}
                  className={`basis-1/7 p-[12px] border border-beerus h-[110px] py-2 text-[14px] border-r order-s-0 justify-center inline-flex items-center ${
                    dayInfo?.allocation?.isOnLeave
                      ? "bg-zinc-200 text-raditz"
                      : dayInfo?.allocation?.isDayShift ||
                        dayInfo?.allocation?.isNightShift
                      ? ""
                      : dayInfo?.allocation?.isOnTimeOff
                      ? "bg-teal-50 text-teal-400"
                      : ""
                  } ${
                    dayInfo?.allocation?.dayNumber === 20 ? "highlighted" : ""
                  } ${dayInfo.isCurrentMonth ? "" : "opacity-40"}`}
                >
                  <div className="w-full h-full flex flex-col">
                    <div className="flex items-center justify-between">
                      {moment(dayInfo?.date).format("DD")}
                      {/* Convert date to string */}
                      {dayInfo?.allocation?.isOnLeave ? (
                        ""
                      ) : dayInfo?.allocation?.isDayShift ? (
                        <span
                          className={`inline-flex items-center justify-center w-6 h-6 text-krillin ${
                            selectedShift !== "moon" && "bg-krillin-10"
                          } text-moon-14 rounded-md`}
                        >
                          {selectedShift !== "moon" && <Icon icon="uil:sun" />}
                        </span>
                      ) : dayInfo?.allocation?.isNightShift ? (
                        <span
                          className={`inline-flex items-center justify-center w-6 h-6 ${
                            selectedShift !== "sun" && "bg-beerus"
                          }  text-moon-14 rounded-md`}
                        >
                          {/* Replace this with the night shift icon */}
                          {/* <Icon icon="uil:moon" /> */}
                          {selectedShift !== "sun" && <Icon icon="uil:moon" />}
                        </span>
                      ) : dayInfo?.allocation?.isOnTimeOff ? (
                        ""
                      ) : null}
                    </div>
                    <div className="text-center flex-1 w-full flex items-center justify-center pb-[21px] ">
                      {dayInfo?.allocation?.isDayShift ||
                      dayInfo?.allocation?.isNightShift ? (
                        ""
                      ) : dayInfo?.allocation?.isOnLeave ? (
                        <span className="font-semibold">
                          {dayInfo?.allocation?.leaveType}
                        </span>
                      ) : dayInfo?.allocation?.isOnTimeOff ? (
                        <span className="font-semibold">RO</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ))}
      </>
      {/* </SimpleBar> */}
      <ScheduleNurseDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={handleClose}
        data={specificDayRecord}
      />
    </>
  );
};
