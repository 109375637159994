import React from 'react'
import useIdleTime from './hooks/useIdletime'

const RoutesWrapper = ({children}:{children:JSX.Element}) => {
 useIdleTime()
  return (
    <>
     {children} 
    </>
  )
}

export default RoutesWrapper
