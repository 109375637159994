import React, { useState } from "react";
import { OtherMoon } from "@heathmont/moon-icons-tw";
import { Icon } from "@iconify/react";

const FilterShift = () => {
  const [selectedButton, setSelectedButton] = useState("sun");

  const handleButtonClick = (button: string) => {
    setSelectedButton(button);
  };
  return (
    <div className="flex items-center justify-center gap-1 p-1 bg-goku rounded-moon-s-sm">
      <button
        className={`px-3 py-1 flex items-center justify-center w-full whitespace-nowrap text-moon-24  font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${selectedButton === "sun" ? "bg-gohan text-krillin " : "text-trunks"
          }`}
        onClick={() => handleButtonClick("sun")}
      >
        <Icon icon="uil:sun" />
      </button>
      <button
        className={`px-3 py-1 flex items-center justify-center w-full whitespace-nowrap text-moon-24 font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${selectedButton === "moon" ? "bg-gohan text-slate-900" : "text-trunks"
          }`}
        onClick={() => handleButtonClick("moon")}
      >
        <OtherMoon />
      </button>
    </div>
  );
};

export default FilterShift;
