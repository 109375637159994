import { MenuItem } from "@heathmont/moon-core-tw";
import React, { useState, useCallback, useEffect } from "react";

interface SubOption {
  id: number;
  label: string;
  isSelected: boolean;
}

interface Option {
  id: number;
  label: string;
  isSelected: boolean;
  subMenu?: SubOption[];
}

interface MenuItemData {
  id: number;
  label: string;
  isSelected: boolean;
  subItems: Option[];
}

interface FilterSideBarProps {
  onDataUpdate: (data: MenuItemData[]) => void;
  sidebarData: MenuItemData[];
}

const FilterSideBar: React.FC<FilterSideBarProps> = ({ onDataUpdate, sidebarData }) => {
  const [menuItems, setMenuItems] = useState<MenuItemData[]>(sidebarData);

  const handleOptionClick = useCallback(
    (menuId: number, optionId: number, subOptionId?: number) => {
      setMenuItems((prevMenuItems) =>
        prevMenuItems.map((menuItem) => {
          if (menuItem.id === menuId) {
            const updatedSubItems = menuItem.subItems.map((subItem) => {
              if (subItem.id === optionId) {
                if (subItem.subMenu) {
                  const updatedSubMenu = subItem.subMenu.map((subOption) => {
                    if (subOption.id === subOptionId) {
                      return {
                        ...subOption,
                        isSelected: !subOption.isSelected,
                      };
                    }
                    return subOption;
                  });

                  const isAllSubOptionsSelected = updatedSubMenu.every(
                    (subOption) => subOption.isSelected
                  );

                  const updatedSubItem: Option = {
                    ...subItem,
                    isSelected: isAllSubOptionsSelected,
                    subMenu: updatedSubMenu,
                  };

                  return updatedSubItem;
                } else {
                  return { ...subItem, isSelected: !subItem.isSelected };
                }
              }
              return subItem;
            });

            const isAllSubItemsSelected = updatedSubItems.every(
              (subItem) => subItem.isSelected
            );

            const updatedMenuItem: MenuItemData = {
              ...menuItem,
              isSelected: isAllSubItemsSelected,
              subItems: updatedSubItems,
            };

            return updatedMenuItem;
          }
          return menuItem;
        })
      );
    },
    [setMenuItems]
  );

  useEffect(() => {
    onDataUpdate(menuItems);
  }, [menuItems, onDataUpdate]);

  return (
    <>
      {menuItems.map((menuItem) => (
        <div key={menuItem.id}>
          <MenuItem>
            <MenuItem.Title>
              <p className="leading-6 text-moon-18 font-semibold">
                {menuItem.label}
              </p>
            </MenuItem.Title>
          </MenuItem>
          <div>
            {menuItem.subItems.map((subItem) => (
              <div key={subItem.id}>
                <MenuItem
                  role="checkbox"
                  isSelected={subItem.isSelected}
                  onClick={() => handleOptionClick(menuItem.id, subItem.id)}
                  className="focus:bg-none"
                >
                  <MenuItem.Checkbox className="moon-checked:bg-cyan-600" />
                  <MenuItem.Title>{subItem.label}</MenuItem.Title>
                </MenuItem>

                {subItem.subMenu && (
                  <div className="pl-5">
                    {subItem.subMenu.map((subOption) => (
                      <MenuItem
                        key={subOption.id}
                        role="checkbox"
                        isSelected={subOption.isSelected}
                        onClick={() =>
                          handleOptionClick(
                            menuItem.id,
                            subItem.id,
                            subOption.id
                          )
                        }
                        className="focus:bg-none"
                      >
                        <MenuItem.Checkbox className="moon-checked:bg-cyan-600" />
                        <MenuItem.Title>
                          {subOption.label}
                        </MenuItem.Title>
                      </MenuItem>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default FilterSideBar;
