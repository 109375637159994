import { Snackbar, Button } from "@heathmont/moon-core-tw";
import {
  GenericAlarm,
  GenericCheckRounded,
  GenericClose,
} from "@heathmont/moon-icons-tw";
import { useCallback, useEffect } from "react";

import { NotificationsBellRingingAlternative } from "@heathmont/moon-icons";
import {
  setSnackBar,
  setToastMessage,
} from "../features/ToastSlice/toastSlice";
import { store } from "../store/store";

interface Props {
  snackbar: string;
  toastMessage: string;
}

const Toast: React.FC<Props> = ({ snackbar, toastMessage }) => {
  const openSnackbarHandler = useCallback((type: string, message: string) => {
    store.dispatch(setSnackBar(type));
    store.dispatch(setToastMessage(message));
  }, []);

  useEffect(() => {
    if (snackbar) {
      openSnackbarHandler(snackbar, toastMessage);
    }
  }, []);

  const closeSnackbar = () => {
    // Close the snackbar when you're done with it
    store.dispatch(setSnackBar(""));
    store.dispatch(setToastMessage(""));
  };

  return (
    <>
      <div>
        <Snackbar isOpen={snackbar === "success"} onOpenChange={closeSnackbar}>
          <Snackbar.Icon className="h-10 bg-roshi-10 text-roshi">
            <GenericCheckRounded />
          </Snackbar.Icon>
          <Snackbar.Content>
            <Snackbar.Header>Success</Snackbar.Header>
            <Snackbar.Message>{toastMessage}</Snackbar.Message>
          </Snackbar.Content>
        </Snackbar>
      </div>
      <div>
        <Snackbar isOpen={snackbar === "warning"} onOpenChange={closeSnackbar}>
          <Snackbar.Icon className="h-10 bg-krillin-10 text-krillin">
            <GenericAlarm />
          </Snackbar.Icon>
          <Snackbar.Content>
            <Snackbar.Header>Warning</Snackbar.Header>
            <Snackbar.Message>{toastMessage}</Snackbar.Message>
          </Snackbar.Content>
        </Snackbar>
      </div>
      <div>
        <Snackbar isOpen={snackbar === "error"} onOpenChange={closeSnackbar}>
          <Snackbar.Icon className="h-10 bg-chichi-10 text-chichi">
            <GenericClose />
          </Snackbar.Icon>
          <Snackbar.Content>
            <Snackbar.Header>Error</Snackbar.Header>
            <Snackbar.Message>{toastMessage}</Snackbar.Message>
          </Snackbar.Content>
        </Snackbar>
      </div>
      <div>
        <Snackbar
          isOpen={snackbar === "notification"}
          onOpenChange={closeSnackbar}
        >
          <Snackbar.Icon className="h-10 bg-whis-10 text-whis">
            <NotificationsBellRingingAlternative />
          </Snackbar.Icon>
          <Snackbar.Content>
            <Snackbar.Header>Notification</Snackbar.Header>
            <Snackbar.Message>{toastMessage}</Snackbar.Message>
          </Snackbar.Content>
        </Snackbar>
      </div>
    </>
  );
};

export default Toast;
