import React from "react";
import { Button } from "@heathmont/moon-core-tw";
import CardProfile from "./CardProfile";
import { store } from "../../../store/store";
import { removeUser } from "../../../features/Auth Slice/authSlice";
import { logout } from "../../../services/auth.service";

const ProfileSidebar = () => {
  return (
    <div className="border-r border-beerus flex flex-col items-start bg-white shadow-[0_8px_24px_-6px_rgba(0,0,0,0.08)] py-[43px] px-[28px] h-[calc(100vh-200px)]">
      <div className="flex-1 w-full">
        <CardProfile />
      </div>
      <Button
        variant="tertiary"
        onClick={() => {
          logout();
        }}
      >
        Log Out
      </Button>
    </div>
  );
};

export default ProfileSidebar;
