import { Icon } from "@iconify/react";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { OtherMoon } from "@heathmont/moon-icons-tw";
import { IDayInfo } from "../../../../../../types/interfaces/IDayInfo";

interface Props {
  shiftData: IDayInfo;
}
const DetailShift: React.FC<Props> = ({ shiftData }) => {
  // const { selectedShift } = useSelector((state: RootState) => state.time);
  return (
    <div className="flex flex-wrap -mx-3">
      <div className="px-3 w-1/2 mb-[4px]">
        <div className="flex items-center justify-items-center   min-h-[32px]">
          <div className="flex-1 text-moon-16 text-gray-400">Shift:</div>
          <div className="flex items-center text-moon-16">
            <i
              className={`text-moon-24 ${
                shiftData?.shift === "Day Shift" ? "text-krillin" : ""
              }`}
            >
              {/* <Icon icon="uil:sun" />*/}
              {shiftData?.shift === "Night Shift" ? (
                <OtherMoon />
              ) : shiftData?.shift === "Day Shift" ? (
                <Icon icon="uil:sun" />
              ) : (
                ""
              )}
            </i>
            <span className="ml-[5px]">
              {shiftData?.shift === "Night Shift"
                ? "Night "
                : shiftData?.shift === "Day Shift"
                ? "Day "
                : ""}
            </span>
          </div>
        </div>
      </div>
      <div className="px-3 w-1/2  mb-[4px] ">
        <div className="flex items-center justify-items-center min-h-[32px]">
          <div className="flex-1 text-moon-16 text-gray-400">Total Staff:</div>
          <div className="flex items-center text-moon-16">
            {shiftData?.totalStaff}
          </div>
        </div>
      </div>
      <div className="px-3 w-1/2 mb-[4px]">
        <div className="flex items-center justify-items-center   min-h-[32px]">
          <div className="flex-1 text-moon-16 text-gray-400">Nurses:</div>
          <div className="flex items-center text-moon-16">
            {shiftData?.totalNurses}
          </div>
        </div>
      </div>
      <div className="px-3 w-1/2 mb-[4px]">
        <div className="flex items-center justify-items-center   min-h-[32px]">
          <div className="flex-1 text-moon-16 text-gray-400">HCA</div>
          <div className="flex items-center text-moon-16">
            {shiftData?.totalHCA}
          </div>
        </div>
      </div>

      <div className="px-3 w-1/2 mb-[4px]">
        <div className="flex items-center justify-items-center   min-h-[32px]">
          <div className="flex-1 text-moon-16 text-gray-400">
            Senior Nurses:
          </div>
          <div className="flex items-center text-moon-16">
            {shiftData?.totalSeniorNurses}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailShift;
