import { IYearDropDown } from "../types/interfaces/IDateDropDown.interface";

export const generateYears = (currentYear: number) => {
  const years: IYearDropDown[] = Array.from({ length: 15 }, (_, index) => ({
    name: currentYear - index,
    year: currentYear - index,
    label: "year",
  }));
  return years;
};
