import React, { useState } from "react";
import PageTitle from "../../Components/layouts/header/page-title";
import FilterSideBar from "../../Components/UI/FilterSideBar";
import HeaderFilter from "./widgets/HeaderFilter";
import SwapListing from "./widgets/SwapListing";

type MenuItemData = any;

const Swap = () => {
  const [sidebarData, setSidebarData] = useState<MenuItemData[]>([
    {
      id: 1,
      label: "Status",
      isOpen: true,
      subItems: [
        { id: 1, label: "All", isSelected: false },
        { id: 2, label: "Requested", isSelected: false },
        { id: 3, label: "Approved", isSelected: false },
        { id: 4, label: "Rejected", isSelected: false },
      ],
    },
  ]);

  const handleSidebarDataUpdate = (data: MenuItemData[]) => {
    setSidebarData(data);
  };

  return (
    <div className="bg-[#f8f8f8]">
      <PageTitle
        title="Swap"
        subtitle="List of all your requested duty swaps"
      />
      <div className="flex flex-wrap">
        <div className="w-1/4">
          <div className="border-r border-beerus bg-white shadow-[0_8px_24px_-6px_rgba(0,0,0,0.08)]">
            <div className="bg-white pt-[30px] p-[30px]">
              <h3 className="text-moon-20 font-semibold">Filters</h3>
            </div>
            <div className="bg-gohan flex flex-col gap-2 rounded-moon-s-lg">
              <div className="px-[30px] h-[calc(100vh-284px)]">
                <FilterSideBar
                  onDataUpdate={handleSidebarDataUpdate}
                  sidebarData={sidebarData}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-3/4">
          <div className="p-[20px_30px]">
            <HeaderFilter />
          </div>
          <div className="swaplisting p-[0_20px_30px] h-[calc(100vh-280px)]">
            <SwapListing />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Swap;
