import React, { useEffect, useState } from "react";
import { ControlsPlus, GenericSearch } from "@heathmont/moon-icons-tw";
import Notification from "../../../Widgets/notification/Notification";
import ProfileButton from "../../../Widgets/profile-header/ProfileButton";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { getHospitalData } from "../../../../services/employee.service";

export default function ScheduleHeader({ isLoginRoute }: any) {
  console.log("Header renders");
  const { User } = useSelector((state: RootState) => state.auth);
  const [hospital, setHospital] = useState<any>();
  const location = useLocation().pathname;
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const fetchHospitalData = async () => {
    try {
      const response: any = await getHospitalData(User?.hospital as string);
      if (response) {
        setHospital(response.res.hospitalDB);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (User?.hospital) fetchHospitalData();
  }, [User]);
  return (
    <>
      <header
        className={`py-4 px-7 absolute top-0 right-0 left-0 z-40 w-full ${
          isLoginRoute ? "hidden" : "block"
        }`}
      >
        <nav className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-4">
            <NavLink to="/" className="cursor-pointer">
              <img src={"images/weblogo.svg"} alt="logo" />
            </NavLink>
            {/* <div className="flex items-center rounded-xl bg-primarydark px-2 py-1"> */}
            <div className="flex items-center rounded-xl  px-2 py-1">
              <img
                className="me-2 w-[37px] h-[37px] rounded-full"
                src={hospital ? hospital.logo : ""}
                alt=""
              />
              <div>
                <p className="text-white text-moon-14">
                  {hospital ? hospital.name : "Hospital name"}
                </p>
                <p className=" text-white text-moon-9">{User?.branchName}</p>
              </div>
            </div>
          </div>
          <ul
            className={`flex items-center space-x-9 text-xs cursor-pointer  text-whitelight`}
          >
            <li
              className={`hover:text-white ${
                location === "/" ? "text-white underline" : ""
              }`}
            >
              <NavLink to="/">Home</NavLink>
            </li>
            <li
              className={`hover:text-white ${
                location === "/time-off" ? "text-white underline" : ""
              }`}
            >
              <NavLink to="/time-off">Time-off</NavLink>
            </li>
            <li
              className={`hover:text-white ${
                location === "/swap" ? "text-white underline" : ""
              }`}
            >
              <NavLink to="/swap">Swap</NavLink>
            </li>
            <li
              className={`flex items-center text-white sNeedRoute ? "text-[#272D36]" : ""
              `}
            >
              <ControlsPlus className="text-moon-24 bg-greencustom text-primary me-2 rounded-full " />{" "}
              Contact Support
            </li>
          </ul>
          <ul className="flex items-center space-x-2 text-xs">
            <li className="flex">
              {location !== "/time-off" ? (
                <NavLink
                  to="/time-off"
                  // onClick={handleClick}
                  className="flex items-center w-auto h-auto rounded-lg bg-white text-blackcst  py-1.5 px-3 "
                >
                  <ControlsPlus className="text-moon-24 me-2 " />
                  Apply Time-off
                </NavLink>
              ) : null}{" "}
            </li>
            {/* <li className="flex">
              <NavLink
                to="#"
                className={`inline-flex items-center justify-center w-[30px] h-[30px] rounded-full text-moon-24  text-white bg-primarydarkl p-0 `}
              >
                <GenericSearch />
              </NavLink>
            </li> */}

            <li className="flex">
              <Notification />
            </li>
            <li className="flex">
              <ProfileButton
                value={User?.employeeName as string}
                role={User?.designation as string}
                imageUrl={"./images/Avatar.png"}
              />
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}
