import React from "react";
import FilterTabs from "../filter-tabs/FilterShifts";
import WeekMonthTabs from "../filter-tabs/WeekMonthTabs";
import SectionMain from "../../../../../Components/UI/SectionMain";
import DropDown from "../../../../../Components/UI/DropDown";
import { MonthlySchedulerProps } from "../../../../../types/interfaces/IMonthScheduleProps.interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import moment from "moment";
import FilterShifts from "../filter-tabs/FilterShifts";
import { currentMonth } from "../../../../../features/Time Slice/timeSlice";
import {
  IWeekDropDown,
  IMonthDropDown,
  IYearDropDown,
} from "../../../../../types/interfaces/IDateDropDown.interface";
import { generateYears } from "../../../../../utils/years";
import { months } from "../../../../../utils/months";
import { generateWeeks } from "../../../../../utils/weeks";
// Get the year of the current date
// Get the month of the current date (0-indexed, January is 0)
// const currentMonth = currentDate.toLocaleString("default", { month: "long" });
// const month = [{ name: "November 2023" }, { name: "December 2023" }];

export let data: any;
const NurseScheduleHeader: React.FC<MonthlySchedulerProps> = ({
  year,
  month,
  setYear,
  setMonth,
  week,
  setWeek,
}) => {
  // const { date }: any = useSelector((state: RootState) => state.time);
  const currentWeek = useSelector(
    (state: RootState) => state?.time?.date?.week
  );
  const currentYear: number = Number(moment().format("YYYY")) + 1;

  const selectedCalendar = useSelector(
    (state: RootState) => state.time.selectedCalendar
  );
  // const months: IMonthDropDown[] = Array.from({ length: 12 }, (_, index) => ({
  //   name: `${moment().month(index).format("MMMM")}`,
  //   monthName: moment().month(index).format("MMMM"),
  //   label: index + 1,
  // }));

  // const years: IYearDropDown[] = Array.from({ length: 15 }, (_, index) => ({
  //   name: currentYear - index,
  //   year: currentYear - index,
  //   label: "year",
  // }));

  const years = generateYears(currentYear);

  function getWeeksInMonth() {
    const firstDay = new Date(+year?.year, +month?.label - 1, 1);
    const lastDay = new Date(+year?.year, +month?.label, 0);

    const daysInMonth = lastDay.getDate();
    return Math.ceil(daysInMonth / 7);
  }
  // const numberOfWeekss = getWeeksInMonth();

  // const numberOfWeeks = getWeeksInMonth();
  // const numberOfWeeks = weekCount();
  const numberOfWeeks = getWeeksInMonth();

  // Generate data for the weeks
  // const weeks: IWeekDropDown[] = Array.from(
  //   { length: numberOfWeeks },
  //   (_, index) => ({
  //     name: `Week ${index + 1}`,
  //     weekLabel: "Week",
  //     label: index + 1,
  //   }),
  // );

  const weeks = generateWeeks(numberOfWeeks);
  // Generate data for the weeks
  data = Array.from({ length: numberOfWeeks }, (_, index) => ({
    name: `Week ${index + 1}`,
    weekLabel: "Week",
    label: index + 1,
  }));
  return (
    <div className="py-[14px] px-7  shadow-moon-lg relative z-10">
      <div className="flex gap-[10px] items-center w-full">
        <div className="flex-1 flex gap-[10px]">
          {selectedCalendar === "week" && (
            <div className="max-w-[173px] w-full">
              <DropDown
                field={"name"}
                value={week}
                options={weeks}
                setOptions={setWeek}
              />
            </div>
          )}
          <>
            <div className="max-w-[173px] w-full">
              <DropDown
                field={"monthName"}
                value={month}
                options={months}
                setOptions={setMonth}
              />
            </div>
            <div className="max-w-[173px] w-full">
              <DropDown
                field={"name"}
                value={year}
                options={years}
                setOptions={setYear}
              />
            </div>
          </>
          {/* <div className="max-w-[162px] w-full">
            <SectionMain />
          </div> */}
          <div>
            <FilterShifts />
          </div>
          <div className="ml-auto">
            <WeekMonthTabs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NurseScheduleHeader;
