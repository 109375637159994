import React from "react";
import { MonthCalender } from "../month-calender/MonthCalender";
import { MonthlySchedulerProps } from "../../../../../types/interfaces/IMonthScheduleProps.interface";
import WeekDaysTab from "../../../../../Components/Calendar/full-month-view/WeekDays";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../../types/interfaces/IDateDropDown.interface";

interface Props {
  month: IMonthDropDown;
  year: IYearDropDown;
}
const MonthlyScheduler: React.FC<Props> = ({ month, year }) => {
  return (
    <>
      <WeekDaysTab month={month} year={year} />

      <MonthCalender month={month} year={year} />
    </>
  );
};

export default MonthlyScheduler;
