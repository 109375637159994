import React from "react";
import { Link } from "@heathmont/moon-core-tw/lib/search/private/components/ResultItem";
import moment from "moment";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../types/interfaces/IDateDropDown.interface";

const daysInWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

interface Props {
  month: IMonthDropDown;
  year: IYearDropDown;
}
const WeekDaysTab: React.FC<Props> = ({ month, year }) => {
  const currentDay = moment().day() - 1;
  const isCurrentDate =
    moment()
      .month((month?.label as number) - 1)
      .year(+year?.year)
      .format("DD MM YYYY") === moment().format("DD MM YYYY");
  return (
    <>
      <div className=" bg-sky1">
        <ul className="flex flex-row items-center">
          {daysInWeek.map((day, index) => (
            <li
              key={index}
              className={`basis-1/7 py-4 border border-beerus text-center ${
                currentDay === index && isCurrentDate
                  ? "bg-black text-white"
                  : ""
              }`}
            >
              <Link
                className={`text-sm  font-semibold ${
                  currentDay === index && isCurrentDate
                    ? " text-white"
                    : "text-grey600"
                }`}
                index={0}
              >
                {day}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default WeekDaysTab;
