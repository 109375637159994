import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RootState, store } from "../../../../../store/store";
import { setSelectedCalendar } from "../../../../../features/Time Slice/timeSlice";
import { Button } from "@heathmont/moon-core-tw";
import { useSelector } from "react-redux";

const WeekMonthTabs = () => {
  // const [selectedButton, setSelectedButton] = useState("month");

  const selectedCalendar = useSelector(
    (state: RootState) => state?.time?.selectedCalendar,
  );
  const handleButtonClick = (button: string) => {
    // setSelectedButton(button);
    store.dispatch(setSelectedCalendar(button));
  };
  return (
    <div className="flex items-center justify-center w-fit gap-1 p-1 bg-goku rounded-moon-s-sm">
      <Button
        // to="/nurse-weekly"
        variant="ghost"
        className={`px-3 py-1 gap-1 flex items-center justify-center w-full whitespace-nowrap text-moon-14  font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
          selectedCalendar === "week"
            ? "bg-gohan text-slate-900"
            : "text-trunks"
        }`}
        onClick={() => handleButtonClick("week")}
      >
        Week
      </Button>
      <Button
        // to="/"
        variant="ghost"
        className={`px-3 py-1 gap-1 flex items-center justify-center w-full whitespace-nowrap text-moon-14 font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
          selectedCalendar === "month"
            ? "bg-gohan text-slate-900"
            : "text-trunks"
        }`}
        onClick={() => handleButtonClick("month")}
      >
        Month
      </Button>
    </div>
  );
};

export default WeekMonthTabs;
