import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEmployeeData, IEmployeeDataResponse, IShift } from '../../../types/interfaces/IMonthSchedule.interface';



// Initializing state
const initialState: IEmployeeDataResponse = {
  employeeInfo: {
    _id: '',
    firstName: '',
    lastName: '',
    designation: '',
    shifts: [],
  }
};

// Slicer
const monthScheduleSlice = createSlice({
    name: 'monthSchedule',
    initialState,
    reducers: {
      setMonthSchedule: (state: any, action: any) => { // Product Setter function which sets the state to the data recieved
        state.employeeInfo = action.payload
      },
    
    },
  });
  

  export const { setMonthSchedule } = monthScheduleSlice.actions;
  export default monthScheduleSlice.reducer;