import React, { useState, useEffect } from "react";
import moment from "moment";

const Time = () => {
  const [currentTime, setCurrentTime] = useState<string>(
    moment().format("hh:mm A")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format("hh:mm A"));
    }, 1000); // Update the time every second

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="text-moon-14 bg-blackcst text-white inline-flex font-semibold px-[8px] rounded-md">
      {currentTime}
    </div>
  );
};

export default Time;
