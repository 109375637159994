import React from "react";

const PageTitle = ({ title, subtitle }) => {
  return (
    <div className="pb-5 pt-[100px] px-7 bg-gradient-to-tll">
      <div className="flex flex-wrap items-center -mx-[4px]">
        <div className="w-1/4 px-[4px]">
          <div>
            <h2 className="text-white text-moon-32 font-semibold mb-2 -tracking-[0.5px] mb-2">
              {title}
            </h2>
            <p className="opacity-[0.5] text-white text-moon-16">{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
