import { Button, Drawer, IconButton } from "@heathmont/moon-core-tw";
import {
  ControlsCloseSmall,
  GenericAlarmRound,
} from "@heathmont/moon-icons-tw";
import FilterShift from "./FilterShift";
import CalendarSwap from "./CalendarSwap";
import DropDownUser from "../../../Components/UI/DropDownUser";
const data = [
  { img: "images/Avatar.png", name: "Orthopedics", designation: "Nurse" },
  { img: "images/Avatar.png", name: "ICU", designation: "Chief Nurse" },
  { img: "images/Avatar.png", name: "Orthopedics", designation: "Nurse" },
  { img: "images/Avatar.png", name: "ICU", designation: "Chief Nurse" },
];

const SwapDrawer = (props) => {
  return (
    <Drawer open={props.isOpen} setOpen={props.setIsOpen}>
      <Drawer.Panel className="max-w-[391px] flex flex-col">
        <div className="flex justify-between items-center p-3 border-b border-beerus">
          <h3 className="text-moon-20 font-bold transition-colors">
            Apply Time-off
          </h3>
          <IconButton variant="ghost" onClick={props.handleClose}>
            <ControlsCloseSmall />
          </IconButton>
        </div>
        <div className="p-[24px] flex-1">
          <p className="text-gray-600 text-moon-14 mb-[15px]">
            Select and choose whom to swap with, the request will be send to the
            Nurse manager for approval
          </p>{" "}
          <FilterShift />
          <div className="my-[15px]">
            <CalendarSwap />
          </div>
          <h4 className="mb-[15px]">Swap with</h4>
          <DropDownUser option="Orthopedics" data={data} />
        </div>
        <div className="px-[24px]">
          <div className="flex items-start space-x-2">
            <i className="text-moon-18 leading-1">
              <GenericAlarmRound />
            </i>
            <p className="text-moon-14 text-gray-600 flex-1">
              <span className="font-semibold"> Note:</span>Once you will submit
              the time-off request, it will be undone.
            </p>
          </div>
        </div>
        <hr className="border-t border-beerus mt-[24px]" />
        <div className="p-[24px] flex items-center space-x-3">
          <div>
            <Button variant="ghost" disabled>
              Reset
            </Button>
          </div>
          <div className="flex items-center flex-1 justify-end  space-x-3">
            <Button variant="secondary">Discard</Button>
            <Button className="bg-hit">Apply</Button>
          </div>
        </div>
      </Drawer.Panel>
      <Drawer.Backdrop />
    </Drawer>
  );
};

export default SwapDrawer;
