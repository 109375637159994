import { api } from "../utils/axios";

interface IEditProfile {
  firstName: string;
  lastName: string;
  contactNo: string;
  address: string;
}

export const editEmployeeProfileData = async (params: IEditProfile) => {
  try {
    const { data, ok } = await api.patch(
      `/employee/updateEmployeeProfile`,
      params
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

// {{healthCareDev}}/requests/get

export const getRequests = async (year: number) => {
  try {
    const { data, ok } = await api.get(`/requests/get?&year=${year}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

// {{healthCareDev}}/requests/getLeavesForMonthAndYear/10/2023

export const getLeavesForMonthAndYear = async (month: number, year: number) => {
  try {
    const { data, ok } = await api.get(
      `/requests/getLeavesForMonthAndYear/${month}/${year}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

// {{healthcareLocal}}/requests/create

export const createRequests = async (body: any) => {
  try {
    const { data, ok } = await api.post(`/requests/create`, body);
    if (ok) {

      return { res: data, err: null };
    }
    return {err:true, res:data}
  } catch (error) {
    return { res: null, err: error };
  }
};

// {{healthcareLocal}}/nurse/getShiftsRemainingLeavesWeeklyHours/10/2023/3

export const getShiftsRemainingLeavesWeeklyHours = async (
  month: number,
  year: number,
  week: number
) => {
  try {
    const { data, ok } = await api.get(
      `/nurse/getShiftsRemainingLeavesWeeklyHours/${month}/${year}/${week}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getHospitalData = async (hospitalId: string) => {
  try {
    // console.log(,hospitalId);
    const { data, ok } = await api.get(`hospital/getHospital/${hospitalId}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { err: error };
  }
};

export const getSectionRules = async()=>{
  try {
    const { data, ok } = await api.get(`section/getPreferencesAndRules`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { err: error };
  }
}
interface IChangePassword {
  newPassword: string;
  currentPassword: string;
}
export const changePassword = async (reqBody: IChangePassword) => {
  try {
    const { data, ok } = await api.post(
      `/employee/changePassword/`,
      reqBody
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getIdleTime =async()=>{
  const res = await api.get(`/employee/inActiveTime`)
  if(res.ok){
    return {data : res?.data , err : null}
  }
  return {data:null , err: res?.data}
}