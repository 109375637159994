import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SectionState {
  snackbar: string;
  toastMessage: string;
}

const initialState: SectionState = {
  snackbar: "",
  toastMessage: "",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setSnackBar: (state, action: PayloadAction<string>) => {
      state.snackbar = action?.payload;
    },
    setToastMessage: (state, action: PayloadAction<string>) => {
      state.toastMessage = action?.payload;
    },
  },
});

export const { setSnackBar, setToastMessage } = toastSlice.actions;

export default toastSlice.reducer;
