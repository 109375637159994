import React, { useState, useEffect } from "react";
interface Props {
  designation: string;
}
const Greeting: React.FC<Props> = ({ designation }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update time every second

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);
  const getGreetingAndImage = (time: any) => {
    const currentHour = time.getHours();

    if (currentHour >= 5 && currentHour < 12) {
      // Morning
      return {
        greeting: "Good morning, ",
        imageSrc: "images/morning.svg",
      };
    } else if (currentHour >= 12 && currentHour < 18) {
      // Afternoon
      return {
        greeting: "Good afternoon, ",
        imageSrc: "images/sunshine.svg",
      };
    } else {
      // Evening
      return {
        greeting: "Good evening, ",
        imageSrc: "images/night.svg",
      };
    }
  };

  const { greeting, imageSrc } = getGreetingAndImage(currentTime);

  return (
    <div className="inline-flex text-white gap-[7px] capitalize">
      {greeting} {designation} <img src={imageSrc} alt="" />
    </div>
  );
};

export default Greeting;
