import React, { useEffect, useState } from "react";
import AccountStats from "./AccountStats";
import { getShiftsRemainingLeavesWeeklyHours } from "../../../services/employee.service";
import moment from "moment";
import { currentYear } from "../../../features/Time Slice/timeSlice";
import { generateWeeks } from "../../../utils/weeks";
const AccountHeader = () => {
  const [month, setMonth] = useState({
    name: moment().format("MMMM"),
    monthName: moment().format("MMMM"),
    label: moment().format("M"),
  });
  const [leavesData, setLeavesData] = useState<any>();

  function getWeeksInMonth() {
    const firstDay = new Date(moment().year(), +month.label - 1, 1);
    const lastDay = new Date(moment().year(), +month.label, 0);

    const daysInMonth = lastDay.getDate();
    return Math.ceil(daysInMonth / 7);
  }

  const numberOfWeeks = getWeeksInMonth();

  const weeks = generateWeeks(numberOfWeeks);
  const months = Array.from({ length: 12 }, (_, index) => ({
    name: `${moment().month(index).format("MMMM")}`,
    monthName: moment().month(index).format("MMMM"),
    label: index + 1,
  }));

  const [week, setWeek] = useState(weeks[0]);

  const fetchShiftsRemainingLeavesWeeklyHours = async () => {
    const res = await getShiftsRemainingLeavesWeeklyHours(
      +month?.label,
      currentYear,
      week.label
    );
    if (res?.res) {
      setLeavesData(res?.res);
    }
  };

  useEffect(() => {
    fetchShiftsRemainingLeavesWeeklyHours();
  }, [week, month]);
  return (
    <div className="pb-4 pt-[86px] px-7 bg-gradient-to-tll">
      <div className="flex items-center   -mx-[16px]">
        <div className="w-1/4 px-[16px]">
          <div>
            <h3 className="text-[32px] font-semibold -tracking-[0.5px] text-white  mb-8px">
              Account
            </h3>
            <p className="text-white  text-[16px] ">
              Here is an information about yourself
            </p>
          </div>
        </div>
        <div className="w-3/4  px-[16px]">
          <div className="grid grid-cols-4 gap-[15px]">
            {/* {data.map((item, index) => ( */}
            <div>
              <AccountStats
                title={"Total Schedules"}
                value={month}
                statsNumber={leavesData?.totalSchedules}
                outOff={""}
                options={months}
                bgColor="bg-white"
                setOptions={setMonth}
              />
            </div>
            <div>
              <AccountStats
                title={"Remaining Leaves"}
                value={""}
                statsNumber={leavesData?.remainingLeaves}
                outOff={leavesData?.totalLeaves}
                options={[]}
                bgColor="bg-white"
                // setOptions={setWeek}
              />
            </div>
            <div>
              <AccountStats
                title={"Hours Worked"}
                value={weeks[0]}
                statsNumber={leavesData?.hoursWorked}
                outOff={leavesData?.hoursAligned}
                options={weeks}
                bgColor="bg-white"
                setOptions={setWeek}
              />
            </div>
            {/*

            <div>
              <AccountStats
                key={index}
                title={item.title}
                value={item.value}
                statsNumber={item.statsNumber}
                outOff={item.outOff}
                options={item.options}
                bgColor="bg-white"
              />
            </div> */}
            {/* ))} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountHeader;
